import axios from 'axios'

const loginURL = {
    'development': 'http://localhost:61245/',
    'production': 'http://dash.rent.booboolab.com/'
}

const apiURL = {
    'development': 'http://localhost:55577/',
    'production': 'http://api.rent.booboolab.com/'
};

const apiPostData = {Key: 'obsjfdsbjtfsnvuibf983465+-ewb'};

function newFormData(data) {
    const formData = new FormData();
    formData.append('Key', apiPostData.Key);
    if (data) formData.append('Data', typeof data === 'string' ? data : JSON.stringify(data));
    return formData;
}

function logIn(data) {
    return axios.post(`${loginURL[process.env.NODE_ENV]}login`, data, {responseType: 'json'})
}

function checkAuth() {
    return axios.post(`${apiURL[process.env.NODE_ENV]}check`, null, {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function getImageSrc(image, key) {
    return `${apiURL[process.env.NODE_ENV]}${key}/${image}`
}

function getSites() {
    return axios.get(`${apiURL[process.env.NODE_ENV]}get/sites`);
}

function getContacts() {
    return axios.get(`${apiURL[process.env.NODE_ENV]}get/contacts`);
}

function getBrands() {
    return axios.get(`${apiURL[process.env.NODE_ENV]}get/brands`);
}

function getCars() {
    return axios.get(`${apiURL[process.env.NODE_ENV]}get/cars`);
}

function getRentOrders() {
    return axios.get(`${apiURL[process.env.NODE_ENV]}get/rent_orders`);
}

function getMessages() {
    return axios.get(`${apiURL[process.env.NODE_ENV]}get/messages`);
}

function getClients() {
    return axios.get(`${apiURL[process.env.NODE_ENV]}get/clients`)
}

function getUsersComments() {
    return axios.get(`${apiURL[process.env.NODE_ENV]}get/users_comments`)
}

function getRentalOptions() {
    return axios.get(`${apiURL[process.env.NODE_ENV]}get/rental_options`)
}


function setNewBrand(name, image) {
    const formData = newFormData();
    formData.append('Name', name);
    formData.append("Image", image);
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/set/brands`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function setNewCar(data, image) {
    const formData = newFormData(data);
    formData.append("Image", image);
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/set/cars`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function setNewUserComment(data) {
    const formData = newFormData(data)
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/set/users_comments`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function setNewRentalOption(data) {
    const formData = newFormData(data)
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/set/rental_options`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function editSites(data) {
    const formData = newFormData(data);
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/edit/sites`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function editSiteIcons(icons) {
    if (icons) {
        const keys = Object.keys(icons)
        if (keys.length) {
            const formData = newFormData()
            const ids = []
            for (let i = 0; i < keys.length; i++) {
                ids.push(+keys[i])
                formData.append(keys[i], icons[keys[i]])
                if (i === keys.length - 1) {
                    formData.append('Data', JSON.stringify(ids))
                    // console.log(formData, ids)
                    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/edit/site-icons`, formData,
                        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
                }
            }
        } else {
            return new Promise((resolve, reject) => {reject('No icons')})
        }
    } else {
        return new Promise((resolve, reject) => {reject('No icons')})
    }
}

function editContacts(data) {
    const formData = newFormData(data);
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/edit/contacts`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function editMessage(data) {
    const formData = newFormData(data);
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/edit/messages`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function editCar(data, image, key = '') {
    const formData = newFormData(data);
    if ((key && key === '') && image) formData.append("Image", image);
    const pathParam = 'cars' + (key && key !== '' ? '-' : '') + key
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/edit/${pathParam}`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function editBrand(data, image, key = '') {
    const formData = newFormData(data);
    if ((key && key === '') && image) formData.append("Image", image);
    const pathParam = 'brands' + (key && key !== '' ? '-' : '') + key
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/edit/${pathParam}`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function editOrder(data) {
    const formData = newFormData(data)
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/edit/rent_orders`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function editOrderStatus(data) {
    const formData = newFormData(data)
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/edit/order-status`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function editAutomatisation(data) {
    const formData = newFormData(data)
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/edit/automatisation`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function editRentalOption(data, pathParam) {
    const formData = newFormData(data)
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/edit/${pathParam}`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function deleteMessage(id) {
    const formData = newFormData()
    formData.append("Id", id)
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/delete/messages`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

function deleteOrder(id) {
    const formData = newFormData()
    formData.append("Id", id)
    return axios.post(`${apiURL[process.env.NODE_ENV]}admin/delete/rent_orders`, formData,
        {responseType: 'json', headers: {'Authorization': localStorage.c_auth || 'NOTOKEN'}})
}

const http = {
    logIn: logIn,
    checkAuth: checkAuth,
    getImageSrc: getImageSrc,

    getSites: getSites,
    getContacts: getContacts,
    getBrands: getBrands,
    getCars: getCars,
    getRentOrders: getRentOrders,
    getMessages: getMessages,
    getClients: getClients,
    getUsersComments: getUsersComments,
    getRentalOptions: getRentalOptions,

    setNewBrand: setNewBrand,
    setNewCar: setNewCar,
    setNewUserComment: setNewUserComment,
    setNewRentalOption: setNewRentalOption,

    editSites: editSites,
    editSiteIcons: editSiteIcons,
    editContacts: editContacts,
    editMessage: editMessage,
    editCar: editCar,
    editOrder: editOrder,
    editBrand: editBrand,
    editOrderStatus: editOrderStatus,
    editAutomatisation: editAutomatisation,
    editRentalOption: editRentalOption,

    deleteMessage: deleteMessage,
    deleteOrder: deleteOrder
}

export default http;
