<template>
  <div id="set-form" v-if="show">
    <div class="row">
      <span class="title">Добавление машины</span>
      <img src="../assets/close_icon.svg" alt="" class="close" @click="closeForm">
    </div>
    <div class="row inputs">
      <div>
        <span class="row-span">Фото автомобиля</span>
        <div class="file-inp">
          <img id="file-img" src="" alt="" v-if="imageAdded" ref="car_img">
          <label class="file" for="file-input" ref="file">
            Кликните сюда, чтобы добавить
            <input type="file" id="file-input" @input="onImgAdd($event)">
          </label>
        </div>
      </div>
      <div>
        <span class="row-span">Название</span>
        <input type="text" v-model="newCar.name" ref="name">
      </div>
      <div>
        <span class="row-span">Класс</span>
        <select name="" v-model="newCar.class" ref="class">
          <option value="">Выберите класс</option>
          <option value="Hatchback">Hatchback</option>
          <option value="Passenger Van">Passenger Van</option>
          <option value="Sedan">Sedan</option>
        </select>
      </div>
      <div>
        <span class="row-span">Бренд</span>
        <select name="" v-model="newCar.brand" ref="brand">
          <option :value="0">Выберите бренд</option>
          <option :value="b.id" v-for="b in brands" :key="b.id">{{b.name}}</option>
        </select>
      </div>
      <div>
        <span class="row-span">Топливо</span>
        <select name="" v-model="newCar.fuel" ref="fuel">
          <option :value="0">Выберите тип топлива</option>
          <option :value="3">Diesel</option>
          <option :value="1">Hybrid</option>
          <option :value="2">Unleaded 95</option>
        </select>
      </div>
      <div>
        <span class="row-span">Коробка передач</span>
        <select name="" v-model="newCar.transmission" ref="transmission">
          <option :value="0">Выберите тип КПП</option>
          <option :value="1">Automatic</option>
          <option :value="2">Manual</option>
        </select>
      </div>
      <div>
        <span class="row-span">Кол-во пассажиров</span>
        <input type="number" v-model="newCar.passengers" ref="passengers">
      </div>
      <div>
        <span class="row-span">Кол-во дверей</span>
        <input type="number" v-model="newCar.doors" ref="doors">
      </div>
      <div>
        <span class="row-span">Объём двигателя</span>
        <input type="text" v-model="newCar.engine_volume" ref="engine_volume">
      </div>
      <div>
        <span class="row-span">Мин. возраст водителя</span>
        <input type="number" v-model="newCar.minimal_driver_age" ref="minimal_driver_age">
      </div>
      <div>
        <span class="row-span">Макс. вес багажа</span>
        <input type="number" v-model="newCar.max_luggage" ref="max_luggage">
      </div>
      <div>
        <span class="row-span" style="flex: 1;">Опции</span>
        <div class="car-options">
          <div>
            <input type="checkbox" value="A/C" id="A/C" @change="onCarOptionClick" :checked="hasOption('A/C')">
            <label for="A/C">A/C</label>
          </div>
          <div>
            <input type="checkbox" value="ABS" @change="onCarOptionClick" :checked="hasOption('ABS')">
            <label>ABS</label>
          </div>
          <div>
            <input type="checkbox" value="Airbags" @change="onCarOptionClick" :checked="hasOption('Airbags')">
            <label>Airbags</label>
          </div>
          <div>
            <input type="checkbox" value="AUX" @change="onCarOptionClick" :checked="hasOption('AUX')">
            <label>AUX</label>
          </div>
          <div>
            <input type="checkbox" value="Bluetooth" @change="onCarOptionClick" :checked="hasOption('Bluetooth')">
            <label>Bluetooth</label>
          </div>
          <div>
            <input type="checkbox" value="CD/Radio" @change="onCarOptionClick" :checked="hasOption('CD/Radio')">
            <label>CD/Radio</label>
          </div>
          <div>
            <input type="checkbox" value="Central Locking" @change="onCarOptionClick" :checked="hasOption('Central Locking')">
            <label>Central Locking</label>
          </div>
          <div>
            <input type="checkbox" value="Climate Control" @change="onCarOptionClick" :checked="hasOption('Climate Control')">
            <label>Climate Control</label>
          </div>
          <div>
            <input type="checkbox" value="Cruise Control" @change="onCarOptionClick" :checked="hasOption('Cruise Control')">
            <label>Cruise Control</label>
          </div>
          <div>
            <input type="checkbox" value="DVD" @change="onCarOptionClick" :checked="hasOption('DVD')">
            <label>DVD</label>
          </div>
          <div>
            <input type="checkbox" value="Electric Windows" @change="onCarOptionClick" :checked="hasOption('Electric Windows')">
            <label>Electric Windows</label>
          </div>
          <div>
            <input type="checkbox" value="Front Parking Sensors" @change="onCarOptionClick" :checked="hasOption('Front Parking Sensors')">
            <label>Front Parking Sensors</label>
          </div>
          <div>
            <input type="checkbox" value="Immobilizer" @change="onCarOptionClick" :checked="hasOption('Immobilizer')">
            <label>Immobilizer</label>
          </div>
          <div>
            <input type="checkbox" value="Interior: Fabric" @change="onCarOptionClick" :checked="hasOption('Interior: Fabric')">
            <label>Interior: Fabric</label>
          </div>
          <div>
            <input type="checkbox" value="Interior: Leather" @change="onCarOptionClick" :checked="hasOption('Interior: Leather')">
            <label>Interior: Leather</label>
          </div>
          <div>
            <input type="checkbox" value="Parking Camera" @change="onCarOptionClick" :checked="hasOption('Parking Camera')">
            <label>Parking Camera</label>
          </div>
          <div>
            <input type="checkbox" value="Power Steering" @change="onCarOptionClick" :checked="hasOption('Power Steering')">
            <label>Power Steering</label>
          </div>
          <div>
            <input type="checkbox" value="Rear Parking Sensors" @change="onCarOptionClick" :checked="hasOption('Rear Parking Sensors')">
            <label>Rear Parking Sensors</label>
          </div>
          <div>
            <input type="checkbox" value="USB" id="USB" @change="onCarOptionClick" :checked="hasOption('USB')">
            <label for="USB">USB</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row btn-row">
      <button @click="saveForm">Сохранить</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetCarForm",
  props: {
    show: {type: Boolean, default: false},
    car: {type: Object, default: null}
  },
  data() {
    return {
      brands: [],
      imageAdded: false,
      fileData: null,
      newCar: {
        id: 0,
        name: '',
        class: '',
        brand: 0,
        fuel: 0,
        transmission: 0,
        passengers: 1,
        doors: 4,
        engine_volume: '1',
        minimal_driver_age: 21,
        max_luggage: 1,
        options: []
      },
    }
  },
  created() {
    console.log(this.$refs)
    this.$store.dispatch('initCompleted').then(() => {
      this.brands = this.$store.getters.brands;
    })
  },
  destroyed() {
    console.log('CAR FROM DESTROYED')
  },
  watch: {
    car: function () {
      if (this.car != null) {
        this.newCar = this.car
        console.log(JSON.stringify(this.newCar))
        if (this.newCar.image && this.newCar.image !== '') {
          this.imageAdded = true
          this.$forceUpdate()
          this.$nextTick(() => {
            this.$refs.car_img.setAttribute('src', this.newCar.image)
          })
        }
      }
    }
  },
  methods: {
    newCarObjectForSave() {
      return {
        id: this.newCar.id,
        name: this.newCar.name,
        image: this.newCar.image,
        class: this.newCar.class,
        brand: this.newCar.brand,
        fuel: +this.newCar.fuel,
        transmission: +this.newCar.transmission,
        passengers: +this.newCar.passengers,
        doors: +this.newCar.doors,
        engine_volume: this.newCar.engine_volume,
        minimal_driver_age: +this.newCar.minimal_driver_age,
        max_luggage: +this.newCar.max_luggage,
        options: this.newCar.options
      }
    },
    closeForm() {
      this.$emit('close');
      this.newCar = {
        id: 0,
        name: '',
        class: '',
        brand: '',
        fuel: 0,
        transmission: 0,
        passengers: 1,
        doors: 4,
        engine_volume: '1',
        minimal_driver_age: 21,
        max_luggage: 1,
        options: []
      }
    },
    onImgAdd(event, id) {
      if (event && event.target && event.target.files && event.target.files[0]) {
        this.imageAdded = true;
        this.$nextTick().then(() => {
          const reader = new FileReader();
          reader.onload = (function (t) {
            return function (e) {
              const elemId = id && id > 0 ? 'file-img-' + id : 'file-img';
              const elem = document.getElementById(elemId);
              console.log(elemId, elem)
              if (elem) {
                elem.setAttribute('src', e.target.result);
                elem.setAttribute('type', event.target.files[0].type);
                t.fileData = event.target.files[0];
              }
            }
          })(this);
          reader.readAsDataURL(event.target.files[0]);
        });
      }
    },
    saveForm() {
      (() => {
        return new Promise((resolve, reject) => {
          if (this.newCar.id > 0) {
            resolve(undefined)
            return
          }
          const rejectArray = []
          const keys = Object.keys(this.newCar)
          if (!keys.length) {
            reject(undefined)
            return
          }
          for (let i = 0; i < keys.length; i++) {
            if (keys[i] ==='id') continue
            if (this.newCar[keys[i]] === '' || this.newCar[keys[i]] === 0) {
              rejectArray.push(keys[i])
            }
            if (i === keys.length - 1) {
              if (!this.fileData && this.newCar.id === 0) {
                rejectArray.push('file')
              }
              if (rejectArray.length) reject(rejectArray)
              else resolve(undefined)
            }
          }
        });
      })().then(() => {
        const data = {
          file: this.fileData,
          car: this.newCarObjectForSave()
        }
        this.$emit('save', data);
      }).catch(async keys => {
        console.log('Keys returned', keys);
        if (keys) {
          let attempts = 0;
          const interVal = setInterval(() => {
            if (attempts >= 3) {
              clearInterval(interVal);
              return;
            }
            for (let i = 0; i < keys.length; i++) {
              console.log(this.$refs[keys[i]])
              if (this.$refs[keys[i]]) this.$refs[keys[i]].classList.add('empty');
            }
            setTimeout(() => {
              for (let i = 0; i < keys.length; i++) {
                if (this.$refs[keys[i]]) this.$refs[keys[i]].classList.remove('empty');
              }
              attempts++;
            }, 150);
          }, 450);
        }
        // console.error(e);
      });
    },
    onCarOptionClick(e) {
      if (e && e.target) {
        const value = e.target.value;
        console.log(e.target, value);
        if (e.target.checked) {
          this.newCar.options.push(value);
        } else {
          const index = this.newCar.options.indexOf(value);
          if (index >= 0) {
            this.newCar.options.splice(index, 1);
          }
        }
        console.log(JSON.stringify(this.newCar));
      }
    },
    hasOption(value) {
      return this.newCar.options.includes(value)
    }
  }
}
</script>

<style scoped>
#set-form {
  position: absolute;
  top: auto;
  left: 25%;
  width: calc(50% - 20px);
  min-height: 1rem;
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.7);
  padding: 5px 0;
  z-index: 500;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  font-family: "Raleway", sans-serif;
}
#set-form > * {
  width: calc(100% - 30px);
  padding: 10px 15px;
  text-align: left;
  min-height: 1rem;
}
#set-form span {
  cursor: default;
}
#set-form > *:not(:first-child) {
  border-top: 1px solid rgba(0,0,0,0.1);
}
#set-form .title {
  font-size: 1.3rem;
}
#set-form > .row, .inputs > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#set-form > .btn-row {
  justify-content: flex-end;
}
.inputs > div {
  width: 100%;
}
.inputs > div:not(:first-child) {
  margin-top: 20px;
}
#set-form > .inputs {
  flex-direction: column;
  justify-content: flex-start;
}
#set-form .row-span {
  font-size: 1.1rem;
}
.file-inp {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: clamp(350px, 350px, 50%);
}
.file-inp > *:not(:first-child) {
  margin-top: 10px;
}
#file-img {
  max-height: 15rem;
  max-width: 100%;
}
.file {
  display: inline-block;
  position: relative;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
  width: clamp(341px, 341px, 50%);
  padding: 4px;
}
input[type=file] {
  position: absolute;
  bottom: 0;
  opacity: 0;
  height: 1px;
}
input[type=text], input[type=number], select {
  width: clamp(350px, 350px, 50%);
  min-height: 2rem;
  font-size: 1.3rem;
  border-radius: 3px;
  border: none;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
  padding: 0;
  background-color: #ffffff;
  align-items: center;
}
select {
  font-size: 1rem;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
  cursor: pointer;
  padding-top: 0.45rem;
}
button {
  width: 170px;
  font-size: 1rem;
  background: #299a0b;
  background: -moz-linear-gradient(top,  #299a0b 0%, #299a0b 100%);
  background: -webkit-linear-gradient(top,  #299a0b 0%,#299a0b 100%);
  background: linear-gradient(to bottom,  #299a0b 0%,#299a0b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#299a0b', endColorstr='#299a0b',GradientType=0 );

}
button:hover {
  background: #b4ddb4;
  background: -moz-linear-gradient(top,  #b4ddb4 0%, #83c783 17%, #52b152 33%, #008a00 67%, #005700 83%, #002400 100%);
  background: -webkit-linear-gradient(top,  #b4ddb4 0%,#83c783 17%,#52b152 33%,#008a00 67%,#005700 83%,#002400 100%);
  background: linear-gradient(to bottom,  #b4ddb4 0%,#83c783 17%,#52b152 33%,#008a00 67%,#005700 83%,#002400 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4ddb4', endColorstr='#002400',GradientType=0 );
}
.empty {
  /*border: 2px solid red!important;*/
  background-color: rgba(255, 0, 0, 0.4)!important;
}
.close {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.car-options {
  flex: 2;
  /*background-color: #007bff;*/
  min-height: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}
.car-options > div {
  display: flex;
  /*justify-content: space-between;*/
  min-width: 30%;
  max-width: 30%;
  align-items: center;
  margin: 5px;
  flex: 1;
}
.car-options input[type=checkbox] {
  transform: scale(1.5);
  margin-right: 10px;
}
.car-options label {
  font-size: 0.7rem;
}
</style>