<template>
  <transition name="tmpl">
    <div class="template-body" v-if="show" @click.self="$emit('close')">
      <div class="template-form">
        <div class="btns-div">
          <img src="../assets/close_icon.svg" alt="" @click="$emit('close')">
        </div>
        <div class="form-row">
          <label for="t-name">Название шаблона</label>
          <input type="text" id="t-name" v-model="form.name">
        </div>
        <div class="form-row site-sel">
          <label for="sites-sel">Сайт</label>
          <select name="" id="sites-sel" v-model="form.siteId">
            <option :value="0">Ничего не выбрано</option>
            <option :value="s.id" v-for="s in sites" :key="s.id">{{s.name}}</option>
          </select>
        </div>
        <div class="form-row">
          <label for="subject">Заголовок</label>
          <input type="text" id="subject" v-model="form.subject">
        </div>
        <div class="form-row">
          <label for="">Шаблон</label>
          <quill-editor v-model="form.message" :options="editorOptions"></quill-editor>
        </div>
        <div class="form-row">
          <label for="">Email</label>
          <div class="test-email">
            <input type="text">
            <div>Отправить</div>
          </div>
        </div>
        <div class="btns-div bottom">
          <button class="test">Тест</button>
          <button class="close">Закрыть</button>
          <button class="save">Сохранить</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor';

export default {
  name: "TemplateForm",
  components: {
    quillEditor
  },
  props: {
    show: {type: Boolean, default: false}
  },
  data() {
    return {
      sites: [],
      editorOptions: {
        'font-size': '.6rem',
        width: '100%',
        height: 'auto'
      },
      form: {
        siteId: 0,
        name: '',
        subject: '',
        message: ''
      },
      testEmail: ''
    }
  },
  // methods: {
  //
  // },
  created() {
    this.$store.dispatch('initCompleted').then(completed => {
      if (completed) {
        this.sites = this.$store.getters.sites
        console.log(JSON.stringify(this.sites))
      }
    })
  }
}
</script>

<style scoped>
  .tmpl-enter-active, .tmpl-leave-active {
    transition: opacity .5s;
  }
  .tmpl-enter, .tmpl-leave-to {
    opacity: 0;
  }
  .template-body {
    z-index: 1001;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .template-form {
    width: 40%;
    /*padding: 10px 0;*/
    margin: 0 auto;
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
  }
  .template-form > * {
    width: calc(100% - 40px);
    padding: 0 20px;
    margin: 15px 0;
    display: flex;
  }
  .template-form > *:first-child {
    margin: 0 0 10px;
  }
  .template-form > .btns-div {
    justify-content: flex-end;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding: 10px 20px;
  }
  .template-form > .btns-div.bottom {
    border-bottom: none;
    border-top: 1px solid rgba(0,0,0,0.1);
    margin-bottom: 0;
  }
  .template-form > .btns-div > img {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  .template-form > .form-row:not(.site-sel) {
    flex-direction: column;
  }
  .template-form > .form-row.site-sel label {
    margin-bottom: 0;
    align-self: center;
    margin-right: 10px;
  }
  .test-email {
    display: flex;
  }
  .test-email button {
    /*background-color: rgb(40, 167, 69);*/
    /*width: 100%!important;*/
    /*height: 100%!important;*/
    /*border-bottom-left-radius: 0;*/
    /*border-top-left-radius: 0;*/
    /*margin-left: 0;*/
    /*height: 2rem;*/
    /*box-shadow: 1px 1px 4px rgba(0,0,0,0.5);*/
  }
  .test-email input {
    flex: 1;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
  }
  .test-email div {
    background-color: rgb(40, 167, 69);
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    margin-left: 0;
    height: 2rem;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.5);
    color: #ffffff;
    cursor: pointer;
    font-size: .9rem;
    padding: 0 5px;
  }
  input, select {
    /*-moz-appearance: none;*/
    /*-webkit-appearance: none;*/
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 4px rgba(0,0,0,0.5);
    height: 2rem;
  }
  select {
    margin: 0;
    padding: 0;
    cursor: pointer;
  }
  button {
    -moz-appearance: none;
    -webkit-appearance: none;
    height: 2rem;
    border: none;
    border-radius: 5px;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 10px;
    margin-left: 10px;
    color: #ffffff;
  }
  button.test {
    background-color: rgb(23, 162, 184);
  }
  button.close {
    background-color: rgb(90, 98, 104);
  }
  button.save {
    background-color: rgb(0, 110, 229);
  }
  label {
    font-family: "Raleway", sans-serif;
    font-size: 1.1rem;
    font-weight: 300;
    text-align: left;
    margin-bottom: 10px;
    height: 1.1rem;
  }

  .quill-editor {
    border: none!important;
    border-radius: 10px!important;
    /*overflow: hidden;*/
    box-shadow: 1px 1px 4px rgba(0,0,0,0.5);
  }
</style>