<template>
  <div class="messages">
    <div class="row head">
      <div>#</div>
      <div>Сайт</div>
      <div>Имя</div>
      <div>Почта</div>
      <div>Телефон</div>
      <div>Тема</div>
      <div>Сообщение</div>
      <div>Статус</div>
      <div>Добавлено</div>
      <div></div>
    </div>
    <div class="row" v-for="(m, i) in messages" :key="i">
      <div>{{m.id}}</div>
      <div>
        <template v-for="s in $store.getters.sites">
          <template v-if="m.site_id === s.id">{{s.name}}</template>
        </template>
      </div>
      <div>{{m.name}}</div>
      <div>{{m.email}}</div>
      <div>{{m.phone}}</div>
      <div>{{m.subject}}</div>
      <div>{{m.message}}</div>
      <div>
        <select name="" id="" v-model="m.status" @change="editMessage($event, m.id)">
          <option :value="0">Новое</option>
          <option :value="1">Просмотрено</option>
          <option :value="2">Отвечено</option>
        </select>
      </div>
      <div>{{m.mdate}}</div>
      <div>
        <button @click="deleteMessage(m.id)">Удалить</button>
      </div>
    </div>
    <Confirm :show="conf.show" :text="conf.text" @confirm="confirmed" @cancel="cancelled"></Confirm>
  </div>
</template>

<script>
import http from "../services/http";

import Confirm from "./Confirm";

export default {
  name: "Messages",
  components: {
    Confirm
  },
  data() {
    return {
      messages: [],
      deleteId: 0,
      conf: {
        show: false,
        text: 'Вы действительно хотите удалить сообщение?'
      }
    }
  },
  created() {
    http.getMessages().then(r => {
      if (!r.data.error) {
        this.messages = r.data.messages || []
      }
    })
  },
  methods: {
    openNotification(text, type, duration) {
      this.$emit('notification', {text, type, duration})
    },
    editMessage(e, id) {
      if (e && e.target) {
        const data = {
          id,
          status: +e.target.value
        }
        http.editMessage(data).then(r => {
          if (!r.data.error) {
            this.openNotification('Сообщение успешно отредактировано')
            http.getMessages().then(resp => {
              if (!resp.data.error) this.messages = resp.data.messages || []
            })
          } else {
            this.openNotification('Ошибка редактирования', 'danger')
          }
        })
      }
    },
    deleteMessage(id) {
      this.deleteId = id
      this.conf.show = true
    },
    confirmed() {
      http.deleteMessage(this.deleteId).then(r => {
        if (!r.data.error) {
          this.openNotification('Сообщение удалено')
          http.getMessages().then(resp => {
            if (!resp.data.error) this.messages = resp.data.messages || []
          })
        } else {
          this.openNotification('Ошибка удаления', 'danger')
        }
      })
    },
    cancelled() {
      this.conf.show = false
      this.deleteId = 0
    }
  }
}
</script>

<style scoped>
.messages {
  width: 100%;
  min-height: 2rem;
  /*background-color: #42b983;*/
  display: flex;
  flex-direction: column;
  padding: 20px 0;
}
.messages > .row {
  width: 100%;
  display: flex;
  min-height: 2rem;
  align-items: center;
}
.messages > .row.head {
  font-weight: 700;
}
.messages > .row:not(:first-child) {
  margin-top: 10px;
}
.row > * {
  /*border-right: 1px solid rgba(0,0,0,0.4);*/
  /*height: 2rem;*/
  /*align-items: center;*/
}
.row > *:first-child {
  flex: 1;
}
.row > *:not(:first-child) {
  flex: 2;
}
.row button {
  background-color: darkred;
  /*max-height: 2rem;*/
  width: 60%;
  margin: auto;
}
</style>