<template>
  <div class="multi-select" @focusout="optionsOpened = false" ref="multiselect" tabindex="0">
    <div @click="optionsOpened = !optionsOpened" class="selected-string">{{selected.join(',')}}</div>
    <div @click="optionsOpened = !optionsOpened">
      <div class="arrow" :class="{'opened': optionsOpened}"></div>
    </div>
    <transition name="options"
    @enter="onTransitionEnter"
    @leave="onTransitionLeave">
      <div class="options" v-if="optionsOpened">
        <div class="option-selects">
          <div class="btn" @click="optionSelectsClick(true)"><span>Select all</span></div>
          <div class="btn" @click="optionSelectsClick"><span>Deselect all</span></div>
        </div>
        <template v-for="(option, i) in options">
          <template v-if="optionValid(option)">
            <template v-if="withTitles">
              <div class="option-title" :key="option.key">
                {{option.title}}
              </div>
              <div class="option sub" v-for="(o, index) in option.values" :key="option.key + index" @click="optionClicked(o, option.key)" :class="{'border-bottom': index === option.values.length - 1}">
                <span>{{o.title}}</span>
                <div class="selected" :style="{'opacity': o.selected ? 1 : 0}">
                  &#10003;
                </div>
              </div>
            </template>
            <template v-else>
              <div class="option" :key="i" @click="optionClicked(option)">
                <img :src="getSrc(option.image)" alt="" v-if="option.image != null">
                <span>{{option.title}}</span>
                <div class="selected" :style="{'opacity': option.selected ? 1 : 0}">
                  &#10003;
                </div>
              </div>
            </template>
          </template>
        </template>
<!--        <div class="option">1sdvfzsdgzsdfg zdfg zdf gzdf gzdfgzdf gzdf gzdf gzdfg zdfg zdf gzdf gzdfg dzfsert3t 34</div>-->
<!--        <div>2</div>-->
<!--        <div>3</div>-->
      </div>
    </transition>
  </div>
</template>

<script>
import http from "../services/http";

export default {
  name: "CustomMultiSelect",
  props: {
    withTitles: {type: Boolean, default: false},
    options: {type: Array, default: () => []},
    imageDir: {type: String, default: ''}
  },
  data() {
    return {
      optionsOpened: false,
      selectedValues: undefined,
      selected: []
    }
  },
  watch: {
    options: function () {
      if (this.options.length) {
        if (this.withTitles) {
          this.selectedValues = {}
          for (let i = 0; i < this.options.length; i++) {
            this.selectedValues[this.options[i].key] = []
            for (let j = 0; j < this.options[i].values.length; j++) {
              if (this.options[i].values[j].selected === true) {
                this.selectedValues[this.options[i].key].push(this.options[i].values[j].value)
                this.selected.push(this.options[i].values[j].title)
              }
              if (i === this.options.length - 1 && j === this.options[i].values.length - 1) console.log(JSON.stringify(this.selectedValues))
            }
          }
        } else {
          this.selectedValues = []
          for (let i = 0; i < this.options.length; i++) {
            if(this.options[i].selected === true) {
              this.selectedValues.push(this.options[i].value)
              this.selected.push(this.options[i].title)
            }
            if (i === this.options.length - 1) console.log(JSON.stringify(this.selectedValues))
          }
        }
      }
    }
  },
  methods: {
    getSrc(image) {
      return http.getImageSrc(image, this.imageDir)
    },
    optionValid(option) {
      if (this.withTitles) {
        if (!option.title) return false
        if (!option.values || !option.values.length) return false
        for (let i = 0; i < option.values.length; i++) {
          if (!option.values[i].title || !option.values[i].value) return false
          if (i === option.values.length - 1) return true
        }
      } else {
        return option.title && option.value
      }
    },
    optionClicked(option, key) {
      console.log(option, key)
      option.selected = !option.selected
      if (option.selected === true) {
        if (key) this.selectedValues[key].push(option.value)
        else this.selectedValues.push(option.value)
        this.selected.push(option.title)
      }
      else {
        if (key) this.selectedValues[key].splice(this.selectedValues[key].indexOf(option.value), 1)
        else this.selectedValues.splice(this.selectedValues.indexOf(option.value), 1)
        this.selected.splice(this.selected.indexOf(option.title), 1)
      }
      console.log(JSON.stringify(this.selectedValues))
      this.$emit('selected', this.selectedValues)
      this.$forceUpdate()
    },
    optionSelectsClick(selected) {
      if (this.withTitles) {
        for (let i = 0; i < this.options.length; i++) {
          for (let j = 0; j < this.options[i].values.length; j++) {
            this.options[i].values[j].selected = selected === true
            if (!this.options[i].values[j].selected) this.selected.splice(this.selected.indexOf(this.options[i].values[j].title), 1)
            else this.selected.push(this.options[i].values[j].title)
            if (i === this.options.length - 1 && j === this.options[i].values.length - 1) this.$forceUpdate()
          }
        }
      } else {
        for (let i = 0; i < this.options.length; i++) {
          this.options[i].selected = selected === true
          if (i === this.options.length - 1) this.$forceUpdate()
        }
      }
    },
    onTransitionEnter(element) {
      element.style.visibility = 'hidden';
      const height = parseInt(getComputedStyle(element).height)
      // console.log(height, -height)
      element.style.visibility = null;
      element.style.height = 0;

      // Force repaint to make sure the
      // animation is triggered correctly.
      getComputedStyle(element).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        element.style.height = height + 'px';
        element.style.bottom = -(height + 11) + 'px'
      });
    },
    onTransitionLeave(element) {
      requestAnimationFrame(() => {
        element.style.height = 0
        element.style.bottom = '-11px'
      });
    }
  }
}
</script>

<style scoped>
  .multi-select {
    max-height: 1.8rem;
    min-height: 1.8rem;
    width: clamp(250px, 48%, 300px);
    border: 1px dotted #000000;
    border-radius: 5px;
    background-color: lightgrey;
    margin: auto;
    display: grid;
    grid-template-columns: auto 12px;
    align-items: center;
    position: relative;
    z-index: 500;
  }
  .multi-select > div:not(.options) {
    height: 100%;
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  .multi-select > div:not(:first-child):not(.options) {
    flex-direction: column;
    justify-content: center;
  }
  .selected-string {
    overflow: hidden;
    padding-left: 5px;
  }
  .arrow {
    width: 5px;
    height: 5px;
    border-bottom: 2px solid #000000;
    border-left: 2px solid #000000;
    transform: rotate(315deg);
  }
  .arrow.opened {
    transform: rotate(135deg);
  }
  .options {
    position: absolute;
    bottom: -11px;
    left: -1px;
    height: auto;
    min-width: calc(100% - 10px);
    background-color: #ffffff;
    transition: height .3s, bottom .3s;
    overflow: hidden;
    padding: 5px;
    border: 1px solid rgba(0,0,0,0.2);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
  .option {
    display: inline-flex;
    justify-content: space-between;
    min-height: 2rem;
    max-height: 2rem;
    width: 100%;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow: hidden;
    align-items: center;
    cursor: pointer;
  }
  .option.sub {
    padding-left: 20px;
    width: calc(100% - 20px);
  }
  .option span {
    margin: 0 10px;
    flex: 1;
    text-align: left;
  }
  .option-selects {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 5px 0 10px;
  }
  .option-selects .btn {
    min-width: 45%;
    max-width: 45%;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.6);
    min-height: 1.5rem;
    max-height: 1.5rem;
    cursor: pointer;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 0.9rem;
    border-radius: 5px;
  }
  .option-title {
    width: 100%;
    text-align: left;
    color: rgba(0,0,0,0.3);
    margin: 10px 0 10px;
    font-size: 0.9rem;
  }
  .border-bottom {
    border-bottom: 1px solid rgba(0,0,0,0.1);
  }
  .option > img {
    width: 50px;
    height: 30px;
    background-color: darkcyan;
  }
  .selected {
    /*content: '';*/
    width: 30px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 1.9rem;
  }
</style>