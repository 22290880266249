<template>
  <div class="settings">
    <div class="menu">
      <button @click="menuClick('contacts')" :class="{'active': currentKeyIs('contacts')}">Контакты</button>
      <button @click="menuClick('brands')" :class="{'active': currentKeyIs('brands')}">Бренды</button>
      <button @click="menuClick('cars')" :class="{'active': currentKeyIs('cars')}">Машины</button>
      <button @click="menuClick('automatisation')" :class="{'active': currentKeyIs('automatisation')}">Автоматизация</button>
      <button @click="menuClick('templates')" :class="{'active': currentKeyIs('templates')}">Шаблоны</button>
      <button @click="menuClick('site_icons')" :class="{'active': currentKeyIs('site_icons')}">Иконки сайтов</button>
      <button @click="menuClick('user_comments')" :class="{'active': currentKeyIs('user_comments')}">Комментарии пользователей</button>
      <button @click="menuClick('rental_options')" :class="{'active': currentKeyIs('rental_options')}">Доп. услуги</button>
    </div>
    <div></div>
    <div class="content">
      <template v-if="currentKeyIs('contacts')">
        <Contacts @notification="$emit('notification', $event)"></Contacts>
      </template>
      <template v-if="currentKeyIs('brands')">
        <Brands @notification="$emit('notification', $event)"></Brands>
      </template>
      <template v-if="currentKeyIs('cars')">
        <Cars @notification="$emit('notification', $event)"></Cars>
      </template>
      <template v-if="currentKeyIs('automatisation')">
        <Automatization @notification="$emit('notification', $event)"></Automatization>
      </template>
      <template v-if="currentKeyIs('templates')">
        <Templates @notification="$emit('notification', $event)"></Templates>
      </template>
      <template v-if="currentKeyIs('site_icons')">
        <SiteIcons @notification="$emit('notification', $event)"></SiteIcons>
      </template>
      <template v-if="currentKeyIs('user_comments')">
        <UsersComments @notification="$emit('notification', $event)"></UsersComments>
      </template>
      <template v-if="currentKeyIs('rental_options')">
        <RentalOptions @notification="$emit('notification', $event)"></RentalOptions>
      </template>
    </div>
  </div>
</template>

<script>
import Contacts from "./Contacts";
import Brands from "./Brands";
import Cars from "./Cars";
import Automatization from "./Automatization";
import Templates from "./Templates";
import SiteIcons from "./SiteIcons";
import UsersComments from "./UsersComments";
import RentalOptions from "./RentalOptions";

export default {
  name: "Settings",
  components: {
    Contacts, Brands, Cars, Automatization, Templates, SiteIcons, UsersComments, RentalOptions
  },
  data() {
    return {
      nav: {
        contacts: true,
        brands: false,
        cars: false,
        automatisation: false,
        templates: false,
        site_icons: false,
        user_comments: false,
        rental_options: false
      }
    }
  },
  created() {
    if (localStorage.sub_set == null) {
      localStorage.setItem('sub_set', 'contacts')
    } else {
      this.menuClick(localStorage.sub_set)
    }
  },
  methods: {
    menuClick(key) {
      Object.keys(this.nav).forEach(k => {
        this.nav[k] = k === key;
      });
      if (localStorage.sub_set !== key) localStorage.setItem('sub_set', key)
    },
    currentKeyIs(key) {
      return this.nav[key] && this.nav[key] === true;
    }
  }
}
</script>

<style scoped>
.settings {
  width: 100vw;
  display: grid;
  grid-template-columns: 18% auto;
}
.settings > .menu {
  display: flex;
  flex-direction: column;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  position: fixed;
  top: calc(1.4rem + 40px);
  left: 0;
  width: 18%;
}
.menu button {
  background: #299a0b;
  background: -moz-linear-gradient(top,  #299a0b 0%, #299a0b 100%);
  background: -webkit-linear-gradient(top,  #299a0b 0%,#299a0b 100%);
  background: linear-gradient(to bottom,  #299a0b 0%,#299a0b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#299a0b', endColorstr='#299a0b',GradientType=0 );
  width: 60%;
  margin: 10px auto;
}
.menu button.active {
  box-shadow: 2px 2px 2px rgba(47, 79, 79,0.8),
              4px 4px 4px rgba(47, 79, 79,0.6),
              6px 6px 6px rgba(47, 79, 79,0.4);
  font-weight: 600;
  transform: scaleX(1.2);

  background: #006e2e;
  background: -moz-linear-gradient(top,  #006e2e 0%, #006e2e 100%);
  background: -webkit-linear-gradient(top,  #006e2e 0%,#006e2e 100%);
  background: linear-gradient(to bottom,  #006e2e 0%,#006e2e 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#006e2e', endColorstr='#006e2e',GradientType=0 );
}
.settings .content {
  padding: 20px;
}
</style>