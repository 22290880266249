<template>
  <div class="templates">
<!--    <SetBrandForm :show="showForm" @save="addBrand" @close="showForm = false">-->
<!--    </SetBrandForm>-->
    <div class="row">
      <div>
        <div @click="showForm = true" class="add-icon"></div>
      </div>
      <div v-for="s in sites" :key="s.id"><b>{{s.name}}</b></div>
    </div>
    <div class="row">
      <div></div>
      <div v-for="s in sites" :key="s.id">
        <button @click="openTemplate(s.id)">{{'test ' + s.name}}</button>
      </div>
<!--      <div class="car-info">-->
<!--        <img :src="b.image" alt="">-->
<!--        <div>-->
<!--          <span v-if="editId !== b.id">{{b.name}}</span>-->
<!--          <input class="car-name" v-model="b.name" v-else>-->
<!--          &lt;!&ndash;          <img src="../assets/pencil.svg" alt="" @click="editClick($event, b.id)">&ndash;&gt;-->
<!--        </div>-->
<!--      </div>-->
<!--      <template v-for="s in sites">-->
<!--        <div :key="s.id + '' + b.id">-->
<!--          <input type="checkbox" :checked="hasValue(b.available_for, s.id)">-->
<!--        </div>-->
<!--      </template>-->
    </div>
    <TemplateForm :show="showForm" @close="showForm = false"></TemplateForm>
  </div>
</template>

<script>
import TemplateForm from "./TemplateForm";

export default {
  name: "Templates",
  components: {
    TemplateForm
  },
  data() {
    return {
      showForm: false,
      sites: [],
      chosenSiteId: 0
    }
  },
  created() {
    this.$store.dispatch('initCompleted').then(completed => {
      if (completed) {
        this.sites = this.$store.getters.sites;
      }
    })
  },
  methods: {
    openTemplate(siteId) {
      if (siteId && siteId > 0) {
        this.chosenSiteId = siteId
        this.showForm = true
      }
    }
  }
}
</script>

<style scoped>
.templates {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  /*background-color: #008a00;*/
  display: flex;
  flex-direction: column;
}
.templates > *:not(img) {
  min-height: 2rem;
}
.templates > img {
  width: 2.5rem;
  height: 2.5rem;
}
.templates > .row {
  width: 100%;
  display: flex;
  min-height: 2rem;
  margin-top: 20px;
}
.templates > .row > * {
  min-height: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.border-bottom {
  border-bottom: 1px solid rgba(0,0,0,0.3);
}
.row > div > button {
  width: 50%;
  margin: auto;
  background-color: cornflowerblue;
}
.add-icon {
  width: 40px;
  height: 40px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.7);
  background: url("../assets/green_plus.svg") no-repeat;
  background-size: 170% 170%;
  background-position: center;
  border-radius: 5px;
  cursor: pointer;
}
</style>