<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  created() {
    this.$store.dispatch('init');
  }
}
</script>

<style>
body {
  padding: 0;
  margin: 0;
}
body * {
  font-family: "Raleway", sans-serif;
}
button {
  border-radius: 5px;
  color: #ffffff;
  padding: 10px 0;
  cursor: pointer;
  text-align: center;
}
a {
  text-decoration: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.printable {
  visibility: hidden;
}

@media print {
  * {
    visibility: hidden;
  }
  .printable, .printable * {
    visibility: visible;
  }
  .printable {
    display: table;
    border-collapse: separate;
    border-spacing: 7.5px;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    padding: 5px;
    font-family: "Raleway", sans-serif;
    font-style: normal;
    font-size: 1.3rem;
  }
  .printable td {
    border: 1px solid rgba(0,0,0,0.2);
    padding: 7.5px 0;
  }
  .printable th, .printable td:not(:first-child) {
    /*min-width: calc(100% - 7.5px);*/
    padding-right: 7.5px;
    text-align: right;
    /*display: flex;*/
    /*justify-content: space-between;*/
  }
  .printable th span {

  }
  .printable td:first-child {
    font-weight: 700;
  }
  .printable td.client {
    display: flex;
    flex-direction: column;
    padding: 7.5px;
  }
  .printable p {
    margin: 2.5px 0;
    display: inline-flex;
    justify-content: space-between;
    flex: 1;
  }
  @page {
    size: landscape;
    margin: 10px;
  }
}
</style>
