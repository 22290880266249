<template>
  <div class="orders">
    <div class="header">
      <div class="date-inputs">
        <label for="date-from">от: </label><input type="date" id="date-from">
        <label for="date-to">до: </label><input type="date" id="date-to">
        <div class="refresh"></div>
      </div>
      <div class="buttons">
        <button class="res">Зарезервировано</button>
        <button class="conf">Подтверждён</button>
        <button class="canc">Изменён</button>
        <button class="garage">В гараже</button>
      </div>
      <div class="selects">
        <CustomMultiSelect :options="otherSelects" :with-titles="true"></CustomMultiSelect>
        <CustomMultiSelect :options="carSelect" :image-dir="'cars'"></CustomMultiSelect>
      </div>
    </div>
    <div class="body">
      <div class="orders-table-header">
        <div>Машина</div>
        <div>Сайт</div>
        <div>Цена</div>
        <div>Куда подать</div>
        <div>Откуда забрать</div>
        <div>Будет свободна</div>
        <div>Заказчик</div>
        <div>Комментарий</div>
        <div>Статус</div>
        <div>Время заказа</div>
        <div>Метод оплаты</div>
        <div>Сумма</div>
        <div>VAT(19%)</div>
        <div>Итого</div>
        <div>Депозит</div>
        <div>## Заказа</div>
      </div>
      <template v-for="(order, i) in orders" >
        <hr :key="'hr-' + i">
        <div class="order-row" :key="i">
          <div class="desired-car">
            <template v-for="c in cars">
              <template v-if="c.id === order.desired_car">
                <img :src="getImageSrc(c.image, 'cars')" alt="" :key="'img-' + c.id">
                <span :key="'car-' + c.id">{{c.name}}</span>
              </template>
            </template>
          </div>
          <div>
            <template v-for="s in $store.getters.sites">
              <template v-if="s.id === order.site_id">{{s.name}}</template>
            </template>
          </div>
          <div>
            {{order.car_price}}
          </div>
          <div>
            <span>{{order.pickup_info.location}}</span>
            <span>{{order.pickup_info.date + ' ' + order.pickup_info.time}}</span>
          </div>
          <div>
            <span>{{order.return_info.location}}</span>
            <span>{{order.return_info.date + ' ' + order.return_info.time}}</span>
          </div>
          <div>
            {{order.free_from}}
          </div>
          <div class="customer">
            <span>{{order.contact_details.full_name}}</span>
            <a :href="'mailto:' + order.contact_details.email">{{order.contact_details.email}}</a>
            <span>{{order.contact_details.phone}}</span>
          </div>
          <div>{{order.contact_details.comments}}</div>
          <div>
            <select v-model="order.status" @change="editStatus($event, order.id)">
              <option :value="0">Зарезервирован</option>
              <option :value="1">Подтверждён</option>
              <option :value="2">Отменён</option>
              <option :value="3">В гараже</option>
            </select>
          </div>
          <div>{{order.order_time}}</div>
          <div>{{order.pay_method}}</div>
          <div>{{order.sub_total}}</div>
          <div>{{order.vat}}</div>
          <div>{{order.grand_total}}</div>
          <div>
            <template v-for="c in cars">
              <template v-if="c.id === order.desired_car">
                {{c.deposit[order.site_id]}}
              </template>
            </template>
          </div>
          <div>{{order.id}}</div>
          <div>
            <button style="background-color: darkgreen;" @click="openEdit(order)">Редактировать</button>
            <button style="background-color: darkred;">Удалить</button>
            <button style="background-color: cornflowerblue;" @click="printOrder(order)">Печать</button>
          </div>
        </div>
        <hr class="bottom" :key="'btm-' + i">
        <table class="days-table" :key="'tbl-' + i">
          <tr>
            <td v-for="day in daysArray(order.pickup_info.date)" :key="day" :title="day" :class="{'rent-day': day >= order.pickup_info.date && day <= order.return_info.date}"></td>
          </tr>
        </table>
      </template>
    </div>
    <Confirm :show="conf.show" :text="conf.text" @confirm="onConfirm" @cancel="closeConfirm"></Confirm>
    <EditOrderForm :show="openEditForm" :order="editOrder" @close="closeEdit" @save="saveEditForm"></EditOrderForm>
    <table class="printable" v-if="orderForPrint.id && orderForPrint.id > 0">
      <col style="width: 20%;">
      <col style="width: auto;">
      <thead>
        <tr>
          <th colspan="2">{{printTime}}</th>
        </tr>
      </thead>
      <tbody>
        <tr><td>## Заказа</td><td>{{orderForPrint.id}}</td></tr>
        <tr>
          <td>Машина</td>
          <td>
            <template v-for="c in cars">
              <template v-if="c.id === orderForPrint.desired_car">
                <span :key="'car-' + c.id">{{c.name}}</span>
              </template>
            </template>
          </td>
        </tr>
        <tr><td>Время заказа</td><td>{{orderForPrint.pickup_info.date + ' ' + orderForPrint.pickup_info.time}}</td></tr>
        <tr><td>Куда подать</td><td>{{orderForPrint.pickup_info.location}}</td></tr>
        <tr><td>Комментарий</td><td>{{orderForPrint.contact_details.comments}}</td></tr>
        <tr>
          <td>Заказчик</td>
          <td class="client">
            <p><b>Имя: </b>{{orderForPrint.contact_details.full_name}}</p>
            <p><b>Телефон: </b>{{orderForPrint.contact_details.phone}}</p
            ><p><b>Почта: </b>{{orderForPrint.contact_details.email}}</p>
          </td>
        </tr>
        <tr><td>Метод оплаты</td><td>{{orderForPrint.pay_method}}</td></tr>
        <tr><td>Депозит</td><td>{{orderForPrint.car_deposit}}</td></tr>
        <tr><td>Цена авто</td><td>{{orderForPrint.car_price}}</td></tr>
        <tr><td>Сумма</td><td>{{orderForPrint.sub_total}}</td></tr>
        <tr><td>VAT(19%)</td><td>{{orderForPrint.vat}}</td></tr>
        <tr><td>Итого</td><td>{{orderForPrint.grand_total}}</td></tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import http from "../services/http";

import EditOrderForm from "./EditOrderForm";
import CustomMultiSelect from "./CustomMultiSelect";
import Confirm from "./Confirm";

export default {
  name: "Orders",
  components: {
    CustomMultiSelect,Confirm,EditOrderForm
  },
  data() {
    return {
      cars: [],
      carSelect: [],
      otherSelects: [],
      orders: [],
      openEditForm: false,
      editOrder: null,
      conf: {
        show: false,
        text: '',
        action: ''
      },
      notification: {
        show: false,
        type: '',
        text: ''
      },
      actionData: null,
      orderForPrint: {},
      printTime: ''
    }
  },
  created() {
    // setTimeout(() => {
    //   this.openNotification('TEST')
    // }, 2000)
    this.$store.dispatch('initCompleted').then(() => {
      const cars = this.$store.getters.cars
      for (let i = 0; i < cars.length; i++) {
        // console.log(JSON.stringify(cars[i]))
        this.carSelect.push({title: cars[i].name, value: cars[i].id, selected: true, image: cars[i].image})
      }
      this.otherSelects = [{title: 'Сайты', key: 'sites', values: []},
        {title: 'Коробка передач', key: 'transmission', values: [{title: 'АКПП', value: 1, selected: true},{title: 'МКПП', value: 2, selected: true}]},
        {title: 'Топливо', key: 'fuel', values: [{title: 'Дизель', value: 3, selected: true},{title: 'Гибрид', value: 1, selected: true},{title: 'Бензин', value: 2, selected: true}]},
        {title: 'Класс авто', key: 'class', values:[]}]
      this.cars = cars
      const sites = this.$store.getters.sites
      for (let i = 0; i < sites.length; i++) {
        this.otherSelects[0].values.push({title: sites[i].name, value: sites[i].id, selected: true})
      }
    })
    http.getRentOrders().then(r => {
      // console.log('ORDERS: ', r)
      if (!r.data.error) this.orders = r.data.orders || []
    })
    // setTimeout(() => {
    //   this.openNotification('TEST!!!', 'success')
    // }, 1500)
  },
  computed: {
    clientDataString() {
      if (!this.orderForPrint.id) return ''
      return this.orderForPrint.contact_details.full_name + '\n' + this.orderForPrint.contact_details.email + '\n' + this.orderForPrint.contact_details.phone
    },
  },
  methods: {
    getImageSrc(image, dir) {
      return http.getImageSrc(image, dir)
    },
    openNotification(text, type) {
      this.$emit('notification', {text, type, duration: 5000})
    },
    daysArray(dateFrom) {
      let date = new Date(dateFrom)
      // console.log(date)
      date = new Date(date.setDate(date.getDate() - 20))
      // console.log(date)
      let dateString = date.getFullYear() + '-' + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + '-' + (date.getDate() < 10 ? '0' : '') + date.getDate()
      const days = [dateString]
      for (let i = 0; i < 60; i++) {
        date = new Date(date.setDate(date.getDate() + 1))
        dateString = date.getFullYear() + '-' + (date.getMonth() < 9 ? '0' : '') + (date.getMonth() + 1) + '-' + (date.getDate() < 10 ? '0' : '') + date.getDate()
        days.push(dateString)
        if (i === 59) return days
      }
    },
    openEdit(order) {
      this.openEditForm = true
      this.editOrder = order
    },
    closeEdit() {
      this.openEditForm = false
      this.editOrder = this.actionData = null
    },
    saveEditForm(e) {
      this.actionData = e
      this.openConfirm('edit', 'Вы действительно хотите редактировать заказ?')
    },
    deleteOrder(id) {
      this.actionData = id
      this.openConfirm('edit', 'Вы действительно хотите удалить заказ?')
    },
    openConfirm(action, text) {
      this.conf.show = true
      this.conf.text = text
      this.conf.action = action
    },
    closeConfirm() {
      this.conf.show = false
      this.conf.text = this.conf.action = ''
    },
    onConfirm() {
      if (this.conf.action === 'edit') {
        http.editOrder(this.actionData).then(r => {
          // console.log(r)
          if (r.data.error) {
            this.openNotification('Не удалось отредактировать заказ', 'danger')
            return
          }
          this.openNotification('Заказ успешно отредактирован', 'success')
          this.closeEdit()
          this.closeConfirm()
        })
      } else if (this.conf.action === 'del') {
        http.deleteOrder(this.actionData).then(r => {
          // console.log(r)
          if (r.data.error) {
            this.openNotification('Не удалось удалить заказ', 'danger')
            return
          }
          this.openNotification('Заказ успешно удалён', 'success')
          this.closeEdit()
          this.closeConfirm()
        })
      }
    },
    editStatus(e, id) {
      if (e && e.target) {
        const data = {
          id,
          status: +e.target.value
        }
        console.log(data)
        http.editOrderStatus(data).then(r => {
          if (r.data.error) {
            this.openNotification('Не удалось сохранить новый статус', 'danger')
            return
          }
          this.openNotification('Статус успешно изменён', 'success')
          http.getRentOrders().then(r => {
            console.log('ORDERS: ', r)
            if (!r.data.error) this.orders = r.data.orders || []
          })
        })
      }
    },
    printOrder(order) {
      this.orderForPrint = order
      this.printTime = new Date().toLocaleString()
      this.$nextTick().then(() => {
        window.print()
        setTimeout(() => {
          this.orderForPrint = {}
          this.printTime = ''
        }, 1000)
      })
    }
  }
}
</script>

<style scoped>
.orders {
  /*min-width: 100%;*/
  /*min-height: 12rem;*/
  /*background-color: #42b983;*/
  display: grid;
  grid-template-rows: 3rem auto;
}
.header {
  grid-row: 1;
  display: flex;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  min-width: 100%;
  max-width: 100%;
}
.header > * {
  display: flex;
  align-items: center;
}
.date-inputs {
  min-width: 20%;
}
.date-inputs * {
  margin-left: 10px;
  font-size: 1rem;
}
.refresh {
  height: 30px;
  width: 30px;
  border: none;
  /*box-shadow: 1px 1px 5px rgba(0,0,0,0.4);*/
  background: url("../assets/refresh.svg") no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  cursor: pointer;
}
.buttons {
  min-width: 35%;
  justify-content: flex-end;
}
.buttons button {
  max-height: 90%;
  /*max-width: 60%;*/
  margin-left: 10px;
  padding: 7px 10px;
  border: none;
}
.res {
  background-color: #ffA500;
}
.conf {
  background-color: #d3d3d3;
}
.canc {
  background-color: #00bfff;
}
.garage {
  background-color: #cd5c5c;
}
.selects {
  min-width: 30%;
  max-width: 30%;
}
.body {
  grid-row: 2;
  /*background-color: lightgrey;*/
  min-height: 1rem;
  width: auto;
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  position: relative;
  border-bottom: 1px solid rgba(0,0,0,0.2);
}
.body > *:not(hr):not(.days-table) {
  min-height: 2rem;
  align-items: center;
  width: auto;
  /*min-width: 100vw;*/
  /*overflow: auto;*/
}
.body > div > *:first-child {
  /*margin-left: 10px;*/
}
.body > div button {
  min-width: 70%;
  max-width: 70%;
  margin: auto;
}
.orders-table-header {
  /*border-bottom: 1px solid rgba(0,0,0,0.2);*/
  /*overflow: visible;*/
}
.orders-table-header, .order-row {
  display: flex;
  flex-wrap: nowrap;
}
.orders-table-header > * {
  /*flex: 1;*/
  font-weight: 700;
  min-width: 10%;
  max-width: 10%;
  padding: 0 5px;
}
hr {
  width: calc(170% + 170px);
  height: 1px;
  background-color: rgba(0,0,0,0.2);
  margin: 2px 0 5px;
  border: none;
}
hr.bottom {
  width: calc(150% + 160px);
  margin: 2.5px 0 2.5px calc(10% + 5px);
}
.days-table {
  width: calc(150% + 140px);
  margin-left: calc(10% + 10px);
  height: 1rem;
  /*background-color: #2c3e50;*/
  border: 1px dashed rgba(0,0,0,0.5);
}
.days-table td:not(:first-child) {
  border-left: 1px dashed rgba(0,0,0,0.5);
}
.days-table td.rent-day {
  background-color: #ffA500;
}
/*.order-row {*/
/*  border-top: 1px solid rgba(0,0,0,0.2);*/
/*}*/
.order-row > * {
  min-width: 10%;
  max-width: 10%;
  display: flex;
  flex-direction: column;
  padding: 0 5px;
}
.order-row > *:not(.desired-car):not(.customer) {
  justify-content: center;
}
.desired-car {
  display: flex;
  /*grid-template-rows: 80% 20%;*/
  flex-direction: column;
  justify-content: space-between;
}
.desired-car * {
  width: 100%;
}
.desired-car img {
  height: 6rem;
  background-color: #2c3e50;
}
.desired-car span {
  margin: 10px 0 -1rem;
  padding-top: -1rem;
}
.customer {
  height: 100%;
  justify-content: space-around;
}
</style>