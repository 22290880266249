import Vue from 'vue'
import Vuex from 'vuex'
import http from "../services/http";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    gettersLength: 3,
    initCompleted: false,
    sites: [],
    brands: [],
    cars: []
  },
  getters: {
    sites(state) {return state.sites},
    brands(state) {return state.brands},
    cars(state) {return state.cars}
  },
  mutations: {
    sites(state, payload) {
      state.sites = payload;
    },
    brands(state, payload) {
      state.brands = payload;
    },
    cars(state, payload) {
      state.cars = payload;
    }
  },
  actions: {
    init() {
      let inited = 0;
      http.getSites().then(r => {
        console.log('vuex site resp: ', r);
        if (!r.data.error) this.state.sites = r.data.sites;
        inited++;
        if (inited >= this.state.gettersLength) this.state.initCompleted = true;
      }).catch(() => {
        inited++;
        if (inited >= this.state.gettersLength) this.state.initCompleted = true;
      });
      http.getBrands().then(r => {
        console.log('vuex brand resp: ', r);
        if (!r.data.error) this.state.brands = r.data.brands;
        inited++;
        if (inited >= this.state.gettersLength) this.state.initCompleted = true;
      }).catch(() => {
        inited++;
        if (inited >= this.state.gettersLength) this.state.initCompleted = true;
      });
      http.getCars().then(r => {
        console.log('vuex cars resp: ', r);
        if (!r.data.error) this.state.cars = r.data.cars;
        inited++;
        if (inited >= this.state.gettersLength) this.state.initCompleted = true;
      }).catch(() => {
        inited++;
        if (inited >= this.state.gettersLength) this.state.initCompleted = true;
      });
    },
    initCompleted() {
      return new Promise(resolve => {
        if (this.state.initCompleted) resolve(true);
        else {
          let attempts = 0;
          let interval = setInterval(() => {
            if (this.state.initCompleted) {
              clearInterval(interval);
              resolve(true);
            } else {
              attempts++;
              if (attempts >= 20) {
                clearInterval(interval);
                resolve(false);
              }
            }
          }, 500);
        }
      });
    },
    refresh({commit}, key) {
      return new Promise(resolve => {
        console.log('REFRESH ', key)
        switch (key) {
          case 'brands':
            http.getBrands().then(r => {
              if (!r.data.error) {
                commit('brands', r.data.brands);
                resolve(true);
              } else {
                resolve(false);
              }
            }).catch(() => {
              resolve(false);
            });
            break;
          case 'sites':
            http.getSites().then(r => {
              if (!r.data.error) {
                commit('sites', r.data.sites);
                resolve(true);
              } else {
                resolve(false);
              }
            }).catch(() => {
              resolve(false);
            });
            break;
          case 'cars':
            http.getCars().then(r => {
              if (!r.data.error) {
                commit('cars', r.data.cars);
                resolve(true);
              } else {
                resolve(false);
              }
            }).catch(() => {
              resolve(false);
            });
        }
      });
    }
  },
  modules: {
  }
})
