<template>
  <div class="form-container">
    <AuthForm @log-in="logIn"></AuthForm>
    <div class="err-div">
      <transition name="error">
        <span v-if="errString !== ''">{{errString}}</span>
      </transition>
    </div>
  </div>
</template>

<script>
import http from "../services/http";
import AuthForm from "@/components/AuthForm";

export default {
  name: "Auth",
  components: {AuthForm},
  data() {
    return {
      errString: ''
    }
  },
  beforeCreate() {
    // console.log('AUTH', this.$router)
    if (localStorage.c_auth != null && localStorage.c_auth !== '') this.$router.replace('/admin')
  },
  methods: {
    logIn(e) {
      console.log(e)
      http.logIn(e).then(r => {
        if (r.data.error) {
          this.setErrString(r.data.error)
          return
        }
        // console.log(r.data)
        const user = r.data.User
        if (user) {
          localStorage.c_auth = user.token
          this.$router.replace('/admin')
        }
      }).catch(e => {
        console.error(e)
        this.setErrString(e)
      })
    },
    setErrString(error) {
      this.errString = error
      setTimeout(() => {
        this.errString = ''
      }, 5000)
    }
  }
}
</script>

<style scoped>
  .form-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .err-div {
    color: #8b0000;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    max-height: 0;
    overflow: visible;
    font-size: 1.5rem;
  }
  .error-enter-active, .error-leave-active {
    transition: opacity 1s;
  }
  .error-enter, .error-leave-to {
    opacity: 0;
  }
  /*.error-enter-to, .error-leave {*/
  /*  opacity: 1;*/
  /*}*/
</style>