<template>
  <div class="u-comm">
    <div class="add-btn-row row">
      <img src="../assets/plus.svg" alt="" @click="newCommentClicked = true">
    </div>
    <div class="head row">
      <div>Имя пользователя</div>
      <div>Комментарий</div>
      <div>Время</div>
      <div></div>
    </div>
    <div class="row" v-for="(c, i) in comments" :key="i">
      <div>{{ c.username }}</div>
      <div>{{ c.comment }}</div>
      <div>{{c.date}}</div>
      <div class="comm-btns">
        <button class="edit">Редактировать</button>
        <button class="del">Удалить</button>
      </div>
    </div>
    <div class="new-comm-body" v-if="newCommentClicked" @click.self="newCommentClicked = false">
      <div class="new-comm-form">
        <div>
          <label for="n-c-1">Имя пользователя:</label><input type="text" id="n-c-1" v-model="newComment.username">
        </div>
        <div>
          <label for="n-c-2">Комментарий:</label>
          <textarea id="n-c-2" v-model="newComment.comment"></textarea>
        </div>
        <div>
          <button @click="saveComment">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../services/http";

export default {
  name: "UsersComments",
  data() {
    return {
      newCommentClicked: false,
      comments: [],
      newComment: {
        username: '',
        comment: ''
      }
    }
  },
  created() {
    http.getUsersComments().then(r => {
      if (!r.data.error) this.comments = r.data.users_comments || []
    })
  },
  methods: {
    saveComment() {
      this.newCommentClicked = false
      const data = {username: this.newComment.username, comment: this.newComment.comment, date: new Date().toLocaleString().replace(',', '')}
      this.newComment.username = this.newComment.comment = ''
      http.setNewUserComment(data).then(r => {
        if (!r.data.error) {
          http.getUsersComments().then(resp => {
            if (!resp.data.error) this.comments = resp.data.users_comments || []
          })
        }
      })
    }
  }
}
</script>

<style scoped>
  .u-comm {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
  }
  .u-comm > div:not(.add-btn-row) {
    width: 100%;
    min-height: 3rem;
  }
  .head, .row {
    display: flex;
  }
  .row.head {
    margin-top: 20px;
  }
  .row:not(.add-btn-row) > *:not(.comm-btns) {
    flex: 1;
    text-align: center!important;
    /*align-items: center;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .row:not(.head):not(.add-btn-row) {
    border-left: 1px solid rgba(0,0,0,0.2);
    border-right: 1px solid rgba(0,0,0,0.2);
    border-bottom: 1px solid rgba(0,0,0,0.2);
  }
  .add-btn-row {
    /*height: 50px;*/
    width: 50px;
    flex-direction: column;
    justify-content: center;
  }
  .add-btn-row img {
    width: 50px;
    height: 50px;
    cursor: pointer;
    /*align-self: center;*/
  }
  .head {
    font-weight: 700;
    border: 1px solid rgba(0,0,0,0.2);
  }
  .row:not(.add-btn-row) > *:not(:first-child) {
    border-left: 1px solid rgba(0,0,0,0.2);
  }
  .comm-btns {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 5px 0;
  }
  .comm-btns button {
    min-width: 40%;
    max-width: 40%;
  }
  .comm-btns .edit {
    background-color: #008a00;
  }
  .comm-btns .del {
    background-color: darkred;
  }
  .new-comm-body {
    width: 100%;
    min-height: 880px!important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .new-comm-form {
    border-radius: 10px;
    background-color: #ffffff;
    /*height: 300px;*/
    width: 50%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .new-comm-form > div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .new-comm-form > div:not(:first-child) {
    margin-top: 20px;
  }
  .new-comm-form label {
    text-align: left;
    margin-bottom: 5px;
  }
  .new-comm-form input[type=text] {
    height: 1.5rem;
  }
  .new-comm-form button {
    background-color: #008a00;
    color: #ffffff;
    width: 150px;
    margin-left: auto;
  }
  .new-comm-form textarea {
    height: 5rem;
    resize: none;
  }
</style>