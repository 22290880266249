<template>
  <div class="brands">
<!--    <SetBrandForm :show="showForm" @save="addBrand" @close="showForm = false"></SetBrandForm>-->
<!--    <div @click="showForm = true" class="add-icon"></div>-->
    <div class="row">
<!--      <div></div>-->
      <div v-for="s in sites" :key="s.id"><b>{{s.name}}</b></div>
    </div>
    <div class="row border-bottom">
<!--      <img src="" alt="" style="opacity: 0;">-->
      <template v-for="(s, i) in sites">
        <div class="img-div" :key="i + '-' + s.id">
          <img :src="getImageSrc(s.icon, 'site-icons')" alt="" :ref="i + '-' + s.id">
          <label :for="i + '-' + s.id"><img src="../assets/pencil.svg" alt="" class="edit-btn" @click="editClicked(s.id)"></label>
          <input type="file" :id="i + '-' + s.id" accept=".png, .svg" @input="onImgAdd($event, i + '-' + s.id)">
        </div>
      </template>
    </div>
    <button v-if="newIconAdded" @click="saveChanges">Сохранить</button>
  </div>
</template>

<script>
import http from "../services/http";

export default {
  name: "SiteIcons",
  data() {
    return {
      sites: [],
      icons: [],
      showForm: false,
      editId: 0,
      newIconAdded: false,
      iconData: {}
    }
  },
  created() {
    this.$store.dispatch('initCompleted').then(() => {
      this.sites = this.$store.getters.sites
    })
  },
  methods: {
    getImageSrc(image, key) {
      return http.getImageSrc(image, key)
    },
    notify(text, type, duration) {
      this.$emit('notification', {text, type, duration})
    },
    editClicked(siteId) {
      this.editId = siteId
    },
    onImgAdd(event, id) {
      if (event && event.target && event.target.files && event.target.files[0]) {
        // this.imageAdded = true;
        this.$nextTick().then(() => {
          const reader = new FileReader();
          reader.onload = (function (t) {
            return function (e) {
              // const elemId = id && id > 0 ? 'file-img-' + id : 'file-img';
              let elem = t.$refs[id]
              if (elem) elem = elem[0]
              // console.log(id, elem)
              if (elem) {
                elem.setAttribute('src', e.target.result);
                elem.setAttribute('type', event.target.files[0].type);
                t.newIconAdded = true
                // t.iconData = event.target.files[0]
                t.iconData[t.editId] = event.target.files[0]
              }
            }
          })(this);
          reader.readAsDataURL(event.target.files[0]);
        });
      }
    },
    saveChanges() {
      // console.log(this.editId, this.iconData)
      http.editSiteIcons(this.iconData).then(r => {
        console.log(r)
        if (r.data.error) this.notify('Something went wrong', 'danger')
        else {
          this.notify('Успешно сохранено')
          this.newIconAdded = false
          this.iconData = {}
          this.editId = 0
          this.$store.dispatch('refresh', 'sites').then(() => {
            this.sites = this.$store.getters.sites
          })
        }
      })
    }
  }
}
</script>

<style scoped>
.brands {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  /*background-color: #008a00;*/
  display: flex;
  flex-direction: column;
}
.brands > *:not(img) {
  min-height: 2rem;
}
.brands > img {
  width: 2.5rem;
  height: 2.5rem;
  margin: auto;
}
.brands > .row {
  width: 100%;
  display: flex;
  justify-content: space-between;
  min-height: 2rem;
  margin-top: 20px;
}
/*.brands > .row > * {*/
/*  min-height: 2rem;*/
/*  flex: 1;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  justify-content: center;*/
/*}*/
.row > * {
  align-self: center;
  flex: 1;
}
.img-div {
  display: flex;
  flex-direction: column;
}
.car-info {
  display: flex;
  flex-direction: column;
}
.row img:not(.edit-btn) {
  width: 4rem;
  height: 4rem;
  /*background-color: #007bff;*/
  margin:  0 auto 20px auto;
}
.edit-btn {
  width: 30px;
  height: 30px;
  margin:  0 auto 20px auto;
  cursor: pointer;
}
.car-info > div {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.car-info > div img {
  width: 25px;
  height: 25px;
  margin-left: 30px;
  cursor: pointer;
  /*background-color: #007bff;*/
}
.border-bottom {
  border-bottom: 1px solid rgba(0,0,0,0.3);
}
input[type=file] {
  height: 1px;
  opacity: 0;
}
button {
  background-color: #008a00;
  width: 200px;
  margin-left: auto;
}
</style>