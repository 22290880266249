<template>
  <div class="admin">
    <Header @nav-click="onNavClick"></Header>
    <template v-if="sectionIsVisible('settings')">
      <Settings @notification="openNotification($event.text, $event.type, $event.duration)"></Settings>
    </template>
    <template v-else-if="sectionIsVisible('orders')">
      <Orders @notification="openNotification($event.text, $event.type, $event.duration)"></Orders>
    </template>
    <template v-else-if="sectionIsVisible('messages')">
      <Messages @notification="openNotification($event.text, $event.type, $event.duration)"></Messages>
    </template>
    <template v-else-if="sectionIsVisible('clients')">
      <Clients @notification="openNotification($event.text, $event.type, $event.duration)"></Clients>
    </template>
    <template v-else>
      <h1>Добро пожаловать в админку!!!</h1>
    </template>
    <Notification :show="notification.show" :type="notification.type" :text="notification.text" :duration="notification.duration" @close="closeNotification"></Notification>
  </div>
</template>

<script>
import Header from "../components/Header";
import Settings from "../components/Settings";
import Orders from "../components/Orders";
import Messages from "../components/Messages";
import Clients from "../components/Clients";
import Notification from "../components/Notification";

import http from "../services/http";

export default {
  name: "Admin",
  components: {
    Header, Settings, Orders, Messages, Clients, Notification
  },
  data() {
    return {
      currentSection: 'home',
      notification: {
        show: false,
        type: '',
        text: '',
        duration: 0
      },
    }
  },
  beforeCreate() {
    http.checkAuth().then(r => {
      if (r.data.error) {
        localStorage.removeItem('c_auth')
        this.$router.replace('/')
      }
    }).catch(() => {
      localStorage.removeItem('c_auth')
      this.$router.replace('/')
    })
  },
  methods: {
    openNotification(text, type = 'success', duration = 3000) {
      this.notification.text = text
      this.notification.type = type
      this.notification.duration = duration
      this.notification.show = true
    },
    closeNotification() {
      this.notification.show = false
      this.$nextTick().then(() => {
        this.notification.type = this.notification.text = ''
        this.notification.duration = 0
      })
    },
    sectionIsVisible(section) {
      return this.currentSection === section;
    },
    onNavClick(e) {
      this.currentSection = e
      localStorage.setItem('lp', e)
    }
  }
}
</script>

<style scoped>
.admin {
  display: flex;
  flex-direction: column;
  position: relative;
  padding-top: calc(1.4rem + 40px);
}
</style>