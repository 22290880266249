<template>
  <div class="header">
    <div @click="onNavClick('home')" :class="{'current': nav.home}">dash.rent.booboolab.com</div>
    <div @click="onNavClick('settings')" :class="{'current': nav.settings}">Настройки</div>
    <div @click="onNavClick('orders')" :class="{'current': nav.orders}">Заказы</div>
    <div @click="onNavClick('messages')" :class="{'current': nav.messages}">Сообщения</div>
    <div @click="onNavClick('clients')" :class="{'current': nav.clients}">Клиенты</div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      nav: {
        home: true,
        settings: false,
        orders: false,
        messages: false,
        clients: false
      }
    }
  },
  beforeCreate() {
    if (localStorage.lp == null) localStorage.lp = 'home'
  },
  created() {
    if (localStorage.lp && (this.nav[localStorage.lp] != null)) {
      this.onNavClick(localStorage.lp)
    }
  },
  methods: {
    onNavClick(key) {
      Object.keys(this.nav).forEach(k => {
        this.nav[k] = k === key;
      });
      this.$emit('nav-click', key);
    }
  }
}
</script>

<style scoped>
.header {
  width: calc(100vw - 40px);
  max-width: calc(100vw - 40px);
  padding: 20px ;
  background-color: #000000;
  color: #ffffff;
  display: flex;
  position: fixed;
  top: 0;
  min-height: 1.4rem;
  max-height: 1.4rem;
  z-index: 1000;
}
.header > * {
  margin-right: 20px;
  cursor: pointer;
}
.header >*:first-child {
  margin-right: 40px;
}
.header >*.current {
  font-weight: 600;
  border-bottom: 2px solid darkcyan;
  color: darkcyan;
}
</style>