<template>
  <div class="clients">
    <div class="search">
      <label for="search">Search:</label>
      <input type="text" id="search" v-model="searchValue">
    </div>
    <div class="head">
      <div>
        <span>Email</span>
        <div class="filter-div">
          <div class="arrow up"></div>
          <div class="arrow down"></div>
        </div>
      </div>
      <div>
        <span>ФИО</span>
        <div class="filter-div">
          <div class="arrow up"></div>
          <div class="arrow down"></div>
        </div>
      </div>
      <div>
        <span>Телефон</span>
        <div class="filter-div">
          <div class="arrow up"></div>
          <div class="arrow down"></div>
        </div>
      </div>
      <div>
        <span>Кол-во заказов</span>
        <div class="filter-div">
          <div class="arrow up"></div>
          <div class="arrow down"></div>
        </div>
      </div>
      <div>
        <span>Кол-во дней</span>
        <div class="filter-div">
          <div class="arrow up"></div>
          <div class="arrow down"></div>
        </div>
      </div>
      <div>
        <span>Кол-во отмен</span>
        <div class="filter-div">
          <div class="arrow up"></div>
          <div class="arrow down"></div>
        </div>
      </div>
      <div>
        <span>Дата регистрации</span>
        <div class="filter-div">
          <div class="arrow up"></div>
          <div class="arrow down"></div>
        </div>
      </div>
      <div v-for="(s, i) in sites" :key="i">{{s.name}}</div>
    </div>
    <template v-for="(c, i) in clients">
      <div class="row" :key="i" v-if="c.show">
        <div>{{ c.email }}</div>
        <div>{{ c.full_name }}</div>
        <div>{{ c.phone }}</div>
        <div>{{ c.orders_cnt }}</div>
        <div>{{ c.days_cnt }}</div>
        <div>{{ c.cancels_cnt }}</div>
        <div>{{ c.reg_date }}</div>
        <div v-for="(s, index) in sites" :key="index">
          <input type="checkbox" disabled :checked="c.site_id === s.id">
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import http from "../services/http";

export default {
  name: "Clients",
  data() {
    return {
      searchValue: '',
      sites: [],
      clients: []
    }
  },
  watch: {
    searchValue: function () {
      for (let i = 0; i < this.clients.length; i++) {
        this.clients[i].show = !(this.searchValue !== '' && (this.clients[i].email.indexOf(this.searchValue) < 0 && this.clients[i].full_name.indexOf(this.searchValue) < 0 &&
            this.clients[i].phone.indexOf(this.searchValue) < 0))
        if (i === this.clients.length - 1) this.$forceUpdate()
      }
    }
  },
  created() {
    this.$store.dispatch('initCompleted').then(() => {
      this.sites = this.$store.getters.sites
      http.getClients().then(r => {
        console.log('Clients: ', r)
        if (!r.data.error) {
          this.clients = r.data.clients || []
          for (let i = 0; i < this.clients.length; i++) {
            this.clients[i].show = true
            if (i === this.clients.length - 1) this.$forceUpdate()
          }
        }
      })
    })
  }
}
</script>

<style scoped>
.clients {
  width: calc(100% - 20px);
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
}
.clients > * {
  width: 100%;
  height: 2rem;
  align-items: center;
  padding: 10px 0;
  display: flex;
}
.search {
  justify-content: flex-end;
  font-size: 1.4rem;
}
.search input {
  height: 1.8rem;
  margin-left: 5px;
  width: 250px;
}
.head {
  font-weight: 600;
  border-bottom: 1px solid #000000;
  font-size: .95rem;
}
.head > *, .row > * {
  flex: 1;
}
.head > * {
  display: flex;
  justify-content: center;
  align-items: center;
}
input[type=checkbox] {
  transform: scale(2.5);
}
.filter-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  margin-left: 10px;
}
.arrow {
  display: inline-block;
  cursor: pointer;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  margin: 2px 0;
}
.up {
  border-bottom: 6px solid rgba(0,0,0,0.5);
}
.down {
  border-top: 6px solid rgba(0,0,0,0.5);
}
</style>