<template>
  <div class="contacts">
    <div class="col">
      <div class="name">Идентификатор сайта</div>
      <div class="name">Телефон</div>
      <div class="name">Whatsapp</div>
      <div class="name">Facebook</div>
      <div class="name">Instagram</div>
      <div class="name">URL для заказа</div>
    </div>
    <template v-for="(site, i) in sites">
      <div class="col" :key="i">
        <input class="input site-name" contenteditable="true" spellcheck="false" @input="onInput('site')" v-model="site.name">
        <template v-for="(contact, index) in contacts">
          <template v-if="contact.site_id === site.id">
            <input class="input" contenteditable="true" @input="onInput('contact')"
                 :key="(i + index) * 0.5" v-model="contact.phone">
            <input class="input" contenteditable="true" @input="onInput('contact')"
                 :key="(i + index + 1) * 0.5" v-model="contact.whatsapp">
            <input class="input" contenteditable="true" @input="onInput('contact')"
                 :key="(i + index + 2) * 0.5" v-model="contact.facebook">
            <input class="input" contenteditable="true" @input="onInput('contact')"
                 :key="(i + index + 3) * 0.5" v-model="contact.instagram">
            <input class="input" contenteditable="true" @input="onInput('contact')"
                 :key="(i + index + 4) * 0.5" v-model="contact.order_url">
          </template>
        </template>
        <button v-if="i === sites.length - 1 && saveButtonVisible()" @click="saveContacts">Сохранить</button>
      </div>
    </template>
  </div>
</template>

<script>
import {sha256} from 'js-sha256'

import http from "../services/http";

export default {
  name: "Contacts",
  data() {
    return {
      contacts: [],
      sites: [],
      sitesEdited: false,
      contactsEdited: false,
      sitesHash: '',
      contactsHash: ''
    }
  },
  created() {
    this.$store.dispatch('initCompleted').then(complete => {
      if (complete) this.sites = this.$store.getters.sites;
      this.sitesHash = sha256.create().update(JSON.stringify(this.sites)).toString()
    });
    this.initContacts()
  },
  methods: {
    initContacts() {
      http.getContacts().then(r => {
        // console.log(r);
        if (r.data.error) {
          return;
        }
        this.contacts = r.data.contacts;
        this.contactsHash = sha256.create().update(JSON.stringify(this.contacts)).toString()
        // alert(this.hash)
        this.$forceUpdate();
        // console.log(JSON.stringify(this.contacts));
      });
    },
    notify(text, type, duration) {
      this.$emit('notification', {text, type, duration})
    },
    saveButtonVisible() {
      const contacts = sha256.create().update(JSON.stringify(this.contacts)).toString()
      const sites = sha256.create().update(JSON.stringify(this.sites)).toString()
      return this.contactsHash !== contacts || this.sitesHash !== sites
    },
    async onInput(key) {
      // console.log(e.target.innerText);
      // console.log('On input', key, subkey, id)
      switch (key) {
        case "site":
          if (!this.sitesEdited) this.sitesEdited = true;
          break;
        case "contact":
          if (!this.contactsEdited) this.contactsEdited = true;
          break;
        default:
      }
    },
    saveContacts() {
      if (!this.sitesEdited && !this.contactsEdited) return;
      if (this.sitesEdited) {
        http.editSites(this.sites).then(r => {
          // console.log(r);
          this.sitesEdited = false;
          if (r.data.error) {
            console.error(r.data.error)
            this.notify('Ошибка редактирования', 'danger')
            return
          }
          this.sitesHash = sha256.create().update(JSON.stringify(this.sites)).toString()
          this.notify('Успешно сохранено')
          this.$store.dispatch('refresh', 'sites').then(() => {
            this.sites = this.$store.getters.sites;
            this.sitesHash = sha256.create().update(JSON.stringify(this.sites)).toString()
          })
        });
      }
      if (this.contactsEdited) {
        http.editContacts(this.contacts).then(r => {
          // console.log(r);
          this.contactsEdited = false;
          if (r.data.error) {
            console.error(r.data.error)
            this.notify('Ошибка редактирования', 'danger')
            return
          }
          this.contactsHash = sha256.create().update(JSON.stringify(this.contacts)).toString()
          this.notify('Успешно сохранено')
          this.initContacts()
        });
      }
    }
  }
}
</script>

<style scoped>
.contacts {
  /*display: flex;*/
  /*flex-direction: column;*/
  display: grid;
  grid-template-columns: 24% 24% 24% 24%;
  grid-column-gap: 1%;
}
.contacts > .col {
  /*display: grid;*/
  /*grid-template-columns: 24% 24% 24% 24%;*/
  /*grid-column-gap: 1%;*/
  /*height: 2rem;*/
  display: flex;
  flex-direction: column;
}
/*.contacts > .row:first-child {*/
/*  border-bottom: 1px solid darkgray;*/
/*}*/
.col > * {
  /*background-color: #42b983;*/
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 1.5rem;
  max-width: 100%;
}
.col > *:not(button) {
  text-align: left;
  font-size: 1.1rem;
}
.col > *:not(.name):not(button) {
  border: 1px dotted gray;
}
.col >*:not(:first-child) {
  margin-top: 10px;
}
.col .name {
  font-weight: 600;
  min-height: calc(1.5rem + 2px);
}
.col .input {
  padding: 0 10px;
  overflow-x: auto;
  border-radius: 2px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.7);
  /*min-height: 2rem;*/
}
.col .input.site-name {
  text-decoration: underline;
  /*text-underline: #008a00;*/
  background-color: rgba(0,0,0,0.1);
}
button {
  background-color: #008a00;
  width: 60%;
  margin-left: auto;
}
</style>