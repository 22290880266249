<template>
  <div id="conf-body" v-if="show">
    <div id="confirm">
      <div><p><b>{{text}}</b></p></div>
      <div style="display: flex; justify-content: space-between;">
        <button style="background-color: darkgreen;" @click="$emit('confirm')">{{confirmButton !== '' ? confirmButton : 'Подтвердить'}}</button>
        <button style="background-color: darkred;" @click="$emit('cancel')">Отмена</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Confirm",
  props: {
    show: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      required: true,
      default: ''
    },
    confirmButton: {
      type: String,
      default: ''
    }
  },
  // watch: {
  //   show: function () {
  //     console.log('Conf watcher', this.show);
  //   }
  // }
}
</script>

<style scoped>
  #conf-body {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    background-color: rgba(0,0,0,0.6);
    z-index: 99999;
    align-items: center;
  }
  #confirm {
    padding: 10px 20px;
    width: 260px;
    height: 200px;
    display: grid;
    grid-template-rows: 70% 25%;
    grid-row-gap: 5%;
    background-color: #ffffff;
    border-radius: 10px;
  }
  p {
    width: 100%;
    text-align: center;
  }
  button {
    width: 125px;
    height: 40px;
    background-color: #D3D3D3;
    text-shadow: 1px 1px 5px rgba(255,255,255,0.6);
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
  }
</style>
