<template>
  <div id="set-form" v-if="show">
    <div class="row">
      <span class="title">Добавить бренд</span>
      <img src="../assets/close_icon.svg" alt="" class="close" @click="closeForm">
    </div>
    <div class="row inputs">
      <div>
        <span class="row-span">Значок бренда</span>
        <div class="file-inp">
          <img id="file-img" src="" alt="" v-if="imageAdded">
          <label class="file" for="file-input" ref="file_input">
            Кликните сюда, чтобы добавить
            <input type="file" id="file-input" @input="onImgAdd($event)">
          </label>
        </div>
      </div>
      <div>
        <span class="row-span">Название</span>
        <input type="text" v-model="inputData" ref="text_input">
      </div>
    </div>
    <div class="row btn-row">
      <button @click="saveForm">Сохранить</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SetBrandForm",
  props: {
    show: {type: Boolean, default: false}
  },
  data() {
    return {
      imageAdded: false,
      fileData: null,
      inputData: ''
    }
  },
  created() {
    console.log(this.$refs);
  },
  methods: {
    closeForm() {
      this.$emit('close');
    },
    onImgAdd(event, id) {
      if (event && event.target && event.target.files && event.target.files[0]) {
        this.imageAdded = true;
        this.$nextTick().then(() => {
          const reader = new FileReader();
          reader.onload = (function (t) {
            return function (e) {
              const elemId = id && id > 0 ? 'file-img-' + id : 'file-img';
              const elem = document.getElementById(elemId);
              console.log(elemId, elem)
              if (elem) {
                elem.setAttribute('src', e.target.result);
                elem.setAttribute('type', event.target.files[0].type);
                t.fileData = event.target.files[0];
              }
            }
          })(this);
          reader.readAsDataURL(event.target.files[0]);
        });
      }
    },
    saveForm() {
      (() => {
        return new Promise((resolve, reject) => {
          switch (true) {
            case !this.fileData && this.inputData === '':
              reject('all');
              break;
            case !this.fileData:
              reject('file');
              break;
            case  this.inputData === '':
              reject('text');
              break;
            default:
              resolve(undefined);
          }
        });
      })().then(() => {
        const data = {
          file: this.fileData,
          input: this.inputData
        }
        this.$emit('save', data);
      }).catch(async e => {
        const refs = await (() => {
          switch (e) {
            case 'all':
              return [this.$refs.file_input, this.$refs.text_input]
            case 'file':
              return [this.$refs.file_input];
            case 'text':
              return [this.$refs.text_input];
            default:
              return [];
          }
        })();
        console.log('Refs returned', refs);
        let attempts = 0;
        const interVal = setInterval(() => {
          if (attempts >= 4) {
            clearInterval(interVal);
            return;
          }
          for (let i = 0; i < refs.length; i++) {
            refs[i].classList.add('empty');
          }
          setTimeout(() => {
            for (let i = 0; i < refs.length; i++) {
              refs[i].classList.remove('empty');
            }
            attempts++;
          }, 150);
        }, 450);
        // console.error(e);
      });
    }
  }
}
</script>

<style scoped>
  #set-form {
    position: absolute;
    top: auto;
    left: 30%;
    width: calc(40% - 20px);
    min-height: 1rem;
    border-radius: 4px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.7);
    padding: 5px 0;
    z-index: 500;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    font-family: "Raleway", sans-serif;
  }
  #set-form > * {
    width: calc(100% - 30px);
    padding: 10px 15px;
    text-align: left;
    min-height: 1rem;
  }
  #set-form span {
    cursor: default;
  }
  #set-form > *:not(:first-child) {
    border-top: 1px solid rgba(0,0,0,0.1);
  }
  #set-form .title {
    font-size: 1.3rem;
  }
  #set-form > .row, .inputs > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  #set-form > .btn-row {
    justify-content: flex-end;
  }
  .inputs > div {
    width: 100%;
  }
  .inputs > div:not(:first-child) {
    margin-top: 20px;
  }
  #set-form > .inputs {
    flex-direction: column;
    justify-content: flex-start;
  }
  #set-form .row-span {
    font-size: 1.1rem;
  }
  .file-inp {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .file-inp > *:not(:first-child) {
    margin-top: 10px;
  }
  #file-img {
    max-height: 15rem;
    max-width: 100%;
  }
  .file {
    display: inline-block;
    position: relative;
    font-size: 0.8rem;
    font-weight: 600;
    cursor: pointer;
    border-radius: 3px;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
    width: clamp(245px, 245px, 50%);
    padding: 4px;
  }
  input[type=file] {
    position: absolute;
    bottom: 0;
    opacity: 0;
    height: 1px;
  }
  input[type=text] {
    width: clamp(250px, 250px, 50%);
    min-height: 2rem;
    font-size: 1.3rem;
    border-radius: 3px;
    border: none;
    box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
    padding: 0;
  }
  button {
    width: 170px;
    font-size: 1rem;
    background: #299a0b;
    background: -moz-linear-gradient(top,  #299a0b 0%, #299a0b 100%);
    background: -webkit-linear-gradient(top,  #299a0b 0%,#299a0b 100%);
    background: linear-gradient(to bottom,  #299a0b 0%,#299a0b 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#299a0b', endColorstr='#299a0b',GradientType=0 );

  }
  button:hover {
    background: #b4ddb4;
    background: -moz-linear-gradient(top,  #b4ddb4 0%, #83c783 17%, #52b152 33%, #008a00 67%, #005700 83%, #002400 100%);
    background: -webkit-linear-gradient(top,  #b4ddb4 0%,#83c783 17%,#52b152 33%,#008a00 67%,#005700 83%,#002400 100%);
    background: linear-gradient(to bottom,  #b4ddb4 0%,#83c783 17%,#52b152 33%,#008a00 67%,#005700 83%,#002400 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4ddb4', endColorstr='#002400',GradientType=0 );
  }
  .empty {
    /*border: 2px solid red!important;*/
    background-color: rgba(255, 0, 0, 0.6);
  }
  .close {
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
</style>