<template>
  <div class="brands">
    <SetBrandForm :show="showForm" @save="addBrand" @close="showForm = false"></SetBrandForm>
    <div @click="showForm = true" class="add-icon"></div>
    <div class="row">
      <div></div>
      <div v-for="s in sites" :key="s.id"><b>{{s.name}}</b></div>
    </div>
    <div class="row border-bottom" v-for="(b, i) in brands" :key="i + '' + b.id">
      <div class="car-info">
        <img :src="getImageSrc(b.image, 'brands')" alt="">
        <div>
          <span>{{b.name}}</span>
<!--          <input class="car-name" v-model="b.name" v-else>-->
<!--          <img src="../assets/pencil.svg" alt="" @click="editClick($event, b.id)">-->
        </div>
      </div>
      <template v-for="s in sites">
        <div :key="s.id + '' + b.id">
          <input type="checkbox" :checked="hasValue(b.available, s.id)" @change="editBrandAvailable($event, b, s.id)">
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import http from "../services/http";
import SetBrandForm from "./SetBrandForm";

export default {
  name: "Brands",
  components: {
    SetBrandForm
  },
  data() {
    return {
      sites: [],
      brands: [],
      showForm: false,
      editId: 0
    }
  },
  created() {
    this.$store.dispatch('initCompleted').then(completed => {
      if (completed) {
        this.sites = this.$store.getters.sites;
        this.brands = this.$store.getters.brands;
      }
    });
  },
  methods: {
    getImageSrc(image, key) {
      return http.getImageSrc(image, key)
    },
    notify(text, type, duration) {
      this.$emit('notification', {text, type, duration})
    },
    addBrand(e) {
      console.log(e);
      http.setNewBrand(e.input, e.file).then(r => {
        if (!r.data.error) {
          this.notify('Новый бренд добавлен', undefined, 2000)
          this.$store.dispatch('refresh','brands').then(success => {
            if (success) this.brands = this.$store.getters.brands;
            this.showForm = false
          });
        } else {
          console.error(r.data.error);
          this.notify('Ошибка', 'danger')
        }
      });
    },
    hasValue(array, value) {
      if (array && array.length) return array.includes(value);
      else return false
    },
    editClick(e, id) {
      if (id && id > 0) {
        if (this.editId <= 0) {
          this.editId = id;
          if (e && e.target) {
            this.$nextTick().then(() => {
              e.target.previousElementSibling.focus();
            });
          }
        }
        else this.editId = 0;
      }
    },
    editBrandAvailable(e, brand, site_id) {
      console.log(brand)
      if (e && e.target) {
        const checked = e.target.checked
        if (checked) brand.available.push(site_id)
        else brand.available.splice(brand.available.indexOf(site_id), 1)
        const data = {
          id: brand.id,
          available: brand.available
        }
        console.log(JSON.stringify(data))
        http.editBrand(data, undefined, 'available').then(r => {
          if (!r.data.error) {
            this.notify('Бренд отредактирован', undefined, 2000)
            this.$store.dispatch('refresh','brands').then(success => {
              if (success) this.brands = this.$store.getters.brands
            });
          } else {
            console.error(r.data.error);
            this.notify('Ошибка', 'danger')
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.brands {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  /*background-color: #008a00;*/
  display: flex;
  flex-direction: column;
}
.brands > *:not(img) {
  min-height: 2rem;
}
.brands > img {
  width: 2.5rem;
  height: 2.5rem;
}
.brands > .row {
  width: 100%;
  display: flex;
  min-height: 2rem;
  margin-top: 20px;
}
.brands > .row > * {
  min-height: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.car-info {
  display: flex;
  flex-direction: column;
}
.car-info > img {
  width: 8rem;
  height: 8rem;
  margin:  0 auto 20px auto;
}
.car-info > div {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
}
.car-info > div img {
  width: 25px;
  height: 25px;
  margin-left: 30px;
  cursor: pointer;
  /*background-color: #007bff;*/
}
.border-bottom {
  border-bottom: 1px solid rgba(0,0,0,0.3);
}
input[type=checkbox] {
  transform: scale(2);
}
.add-icon {
  width: 40px;
  height: 40px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.7);
  background: url("../assets/green_plus.svg") no-repeat;
  background-size: 170% 170%;
  background-position: center;
  border-radius: 5px;
  cursor: pointer;
}
.car-name, .car-info span {
  /*text-align: right;*/
  font-size: 1.1rem;
  padding: 2px;
}
.car-name {
  border: none;
  border-radius: 3px;
  /*background-color: rgba(0,0,0,0.1);*/
  box-shadow: 1px 1px 5px rgba(0,0,0,0.7);
}
</style>