<template>
  <transition name="notification">
    <div v-if="show" class="notification" @click="$emit('close')">
      <div class="container">
        <div class="inner" :class="type">
          <div class="text-div">{{text}}</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Notification",
  props: {
    show: {type: Boolean, default: false},
    type: {type: String, default: 'primary'},
    text: {type: String, default: ''},
    duration: {type: Number, default: 3000}
  },
  data() {
    return {
      timeout: undefined
    }
  },
  watch: {
    show: function () {
      if (this.show) {
        this.timeout = setTimeout(() => {
          // console.log(this.timeout)
          this.$emit('close')
        }, this.duration)
      } else {
        if (this.timeout != null) clearTimeout(this.timeout)
      }
    }
  }
}
</script>

<style scoped>
.notification {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: auto;
  /*width: 100vw;*/
  /*height: 100vh;*/
  transition: all .5s ease;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  /*background-color: rgba(0,0,0,0.1);*/
}
.notification .container {
  box-shadow: 1px 1px 5px rgba(0,0,0,0.6);
  border-radius: 5px;
  width: clamp(400px, 400px, 100vw);
  margin-left: auto;
  background-color: #ffffff;
  float: right;
}
.notification .inner {
  width: 100%;
  min-height: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
}
/*.inner > * {*/
/*  width: 100%;*/
/*}*/
.inner .top-border {
  width: 100%;
  height: 1.5rem;
  z-index: 1;
}
.inner .text-div {
  width: calc(100% - 20px);
  padding: 10px 10px 1rem;
  flex: 1;
  background-color: #FFFFF0;
  text-align: left;
  color: rgba(0,0,0,0.8);
  /*font-size: .9rem;*/
  /*transition: all .3s;*/
  font-family: "Raleway", sans-serif;
}
.inner.primary {
  background-color: rgba(100,149,237,0.9);
}
.inner.success {
  background-color: rgba(0,128,0,0.9);
}
.inner.warn {
  background-color: rgba(255,165,0,1);
}
.inner.danger {
  /*background-color: rgba(255,0,0,0.7);*/
  animation: danger 1.5s infinite steps(20, end);
}
.notification-enter, .notification-leave-to {
  transform: scale(0);
  width: 0;
}
.notification-enter-to, .notification-leave {
  width: clamp(400px, 400px, 100vw);
  transform: scale(1);
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  99% {
    transform: rotate(359deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes danger {
  from {
    background-color: rgba(139, 0, 0, 1);
  }
  /*50 % {*/
  /*  background-color: rgba(255,0,0,1);*/
  /*}*/
  to {
    background-color: rgba(255, 0, 0, 1);
  }
}
@media screen and (max-width: 761px){
  .notification .container {
    min-width: 100vw;
    max-width: 100vw;
  }
}
</style>