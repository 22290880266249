import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from "@/views/Auth";
import Admin from "@/views/Admin";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Auth',
    component: Auth
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  }
]

const router = new VueRouter({
  routes
})

export default router
