<template>
  <div class="form">
    <span>Выполните вход</span>
    <input type="text" placeholder="Эл. почта" v-model="email" ref="email">
    <input type="password" placeholder="Пароль" v-model="password" ref="pass">
    <button @click="loginClicked">Вход</button>
  </div>
</template>

<script>
import {sha256} from 'js-sha256'

export default {
  name: "AuthForm",
  props: {
    refresh: {type: Boolean}
  },
  data() {
    return {
      email: '',
      password: ''
    }
  },
  watch: {
    refresh: function () {
      this.email = this.password = ''
    }
  },
  methods: {
    async loginClicked() {
      let ref = null
      ref = await (() => {
        if (this.email === '') {
          return  this.$refs['email']
        } else if (this.password === '') {
          return  this.$refs['pass']
        } else {
          return null
        }
      })()
      if (ref != null) {
        console.log(ref)
        ref.classList.add('error')
        setTimeout(() => {
          ref.classList.remove('error')
        }, 3000)
        return
      }
      const data = {email: this.email, password: sha256.create().update(this.password).toString()}
      this.$emit('log-in', data)
    }
  }
}
</script>

<style scoped>
  .form {
    width: 300px;
    /*height: 50%;*/
    /*background-color: maroon;*/
    margin: 0 auto;
    display: flex;
    flex-direction: column;
  }
  span {
    width: 100%;
    margin-bottom: 20px;
    font-size: 2rem;
    font-weight: 500;
  }
  input {
    width: 99%;
    /*border: none;*/
    /*margin: auto;*/
    height: 2rem;
    font-size: 1.2rem;
    padding: 0;
    margin-bottom: 1px;
  }
  button {
    width: 50%;
    padding: .5rem 1rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .3rem;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
    margin-left: 25%;
    margin-top: 20px;
    cursor: pointer;
  }
  .error {
    animation: error .6s infinite linear;
  }
  @keyframes error {
    from {
      background-color: #ffff;
    }
    to {
      background-color: #ff0000;
    }
  }
</style>