<template>
  <div id="form" v-if="show">
    <div class="row">
      <span class="title">Редактирование заказа</span>
      <img src="../assets/close_icon.svg" alt="" class="close" @click="closeForm">
    </div>
    <div class="row inputs">
      <div>
        <span class="row-span">Авто</span>
        <select name="" id="car" v-model="editedOrder.desired_car">
          <option :value="c.id" v-for="(c, i) in cars" :key="i">{{c.name}}</option>
        </select>
      </div>
      <div>
        <span class="row-span">Цена</span>
        <input type="text" v-model="editedOrder.car_price" ref="car_price">
      </div>
      <div>
        <span class="row-span">Сайт</span>
        <select name="" id="site" v-model="editedOrder.site_id">
          <option :value="s.id" v-for="(s, i) in sites" :key="i">{{s.name}}</option>
        </select>
      </div>
      <div>
        <span class="row-span">Куда доставить</span>
        <select name="" v-model="editedOrder.pickup_info.location" ref="pickup_location">
          <option :value="o.value" v-for="(o, i) in locationOptions" :key="i">{{o.title}}</option>
        </select>
      </div>
      <div v-if="editedOrder.pickup_info.location === '1' || editedOrder.pickup_info.location === editedOrder.pickup_info.custom">
        <span></span>
        <input type="text" v-model="editedOrder.pickup_info.custom">
      </div>
      <div>
        <span></span>
        <input type="datetime-local" :value="editedOrder.pickup_info.date + ' ' + editedOrder.pickup_info.time" @change="onDateTimeChange($event, 'pickup')">
      </div>
      <div>
        <span class="row-span">Откуда забрать</span>
        <select name="" v-model="editedOrder.return_info.location" ref="pickup_location">
          <option :value="o.value" v-for="(o, i) in locationOptions" :key="i">{{o.title}}</option>
        </select>
      </div>
      <div v-if="editedOrder.return_info.location === '1' || editedOrder.return_info.location === editedOrder.return_info.custom">
        <span></span>
        <input type="text" v-model="editedOrder.return_info.custom">
      </div>
      <div>
        <span></span>
        <input type="datetime-local" :value="editedOrder.return_info.date + ' ' + editedOrder.return_info.time" @change="onDateTimeChange">
      </div>
      <div>
        <span class="row-span">Будет свободна с:</span>
        <input type="datetime-local" v-model="editedOrder.free_from">
      </div>
      <div>
        <span class="row-span">Заказчик</span>
        <input type="text" v-model="editedOrder.contact_details.full_name">
      </div>
      <div><span></span><input type="text" v-model="editedOrder.contact_details.email"></div>
      <div><span></span><input type="text" v-model="editedOrder.contact_details.phone"></div>
      <div>
        <span>Комментарий</span>
        <input type="text" v-model="editedOrder.contact_details.comments">
      </div>
    </div>
    <div class="row btn-row">
      <button @click="saveForm">Сохранить</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditOrderForm",
  props: {
    show: {type: Boolean, default: false},
    order: {type: Object, default: null}
  },
  data() {
    return {
      cars: [],
      sites: [],
      editedOrder: {},
      locationOptions: [
        {value: 'Larnaca Airport', title: 'Larnaca Airport'},{value: 'Paphos Airport', title: 'Paphos Airport'},{value: 'Paphos Office', title: 'Paphos Office'},
        {value: '1', title: 'Your hotel or address'}
      ]
    }
  },
  watch: {
    order: function () {
      console.log('ORDER WATCH: ', JSON.stringify(this.order))
      this.editedOrder = this.order
      if (this.editedOrder) {
        if (this.customLocationEntered(this.editedOrder.pickup_info.location)) this.editedOrder.pickup_info.custom = this.editedOrder.pickup_info.location
        else this.editedOrder.pickup_info.custom = ''
        if (this.customLocationEntered(this.editedOrder.return_info.location)) this.editedOrder.return_info.custom = this.editedOrder.return_info.location
        else this.editedOrder.return_info.custom = ''
      }
    }
  },
  created() {
    this.$store.dispatch('initCompleted').then(completed => {
      if (completed) {
        this.sites = this.$store.getters.sites
        this.cars = this.$store.getters.cars
      }
    })
  },
  methods: {
    saveForm() {
      this.editedOrder.free_from = this.editedOrder.free_from.split('T').join(' ')
      console.log(JSON.stringify(this.editedOrder))
      this.$emit('save', this.editedOrder)
    },
    closeForm() {
      this.$emit('close')
    },
    customLocationEntered(value) {
      if (value === '1') return true
      for (let i = 0; i < this.locationOptions.length; i++) {
        if (value === this.locationOptions[i].value) return false
        if (i === this.locationOptions.length - 1) return true
      }
    },
    onDateTimeChange(e, key) {
      console.log(e.target)
      const dateTimeValues = e.target.value.split('T')
      if (key === 'pickup') {
        this.editedOrder.pickup_info.date = dateTimeValues[0]
        this.editedOrder.pickup_info.time = dateTimeValues[1]
      } else {
        this.editedOrder.return_info.date = dateTimeValues[0]
        this.editedOrder.return_info.time = dateTimeValues[1]
      }
    }
  }
}
</script>

<style scoped>
#form {
  position: absolute;
  top: auto;
  left: 25%;
  width: calc(50% - 20px);
  min-height: 1rem;
  border-radius: 4px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.7);
  padding: 5px 0;
  z-index: 500;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  font-family: "Raleway", sans-serif;
}
#form > * {
  width: calc(100% - 30px);
  padding: 10px 15px;
  text-align: left;
  min-height: 1rem;
}
#form span {
  cursor: default;
}
#form > *:not(:first-child) {
  border-top: 1px solid rgba(0,0,0,0.1);
}
#form .title {
  font-size: 1.3rem;
}
#form > .row, .inputs > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#form > .btn-row {
  justify-content: flex-end;
}
.inputs > div {
  width: 100%;
}
.inputs > div:not(:first-child) {
  margin-top: 20px;
}
#form > .inputs {
  flex-direction: column;
  justify-content: flex-start;
}
#form .row-span {
  font-size: 1.1rem;
}
.file-inp {
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: clamp(350px, 350px, 50%);
}
.file-inp > *:not(:first-child) {
  margin-top: 10px;
}
#file-img {
  max-height: 15rem;
  max-width: 100%;
}
.file {
  display: inline-block;
  position: relative;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 3px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
  width: clamp(341px, 341px, 50%);
  padding: 4px;
}
input[type=file] {
  position: absolute;
  bottom: 0;
  opacity: 0;
  height: 1px;
}
input[type=text], input[type=number], input[type=datetime-local], select {
  width: clamp(350px, 350px, 50%);
  min-height: 2rem;
  font-size: 1.3rem;
  border-radius: 3px;
  border: none;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.3);
  padding: 0;
  background-color: #ffffff;
  align-items: center;
}
select {
  font-size: 1rem;
  text-align-last: center;
  text-align: center;
  -ms-text-align-last: center;
  -moz-text-align-last: center;
  cursor: pointer;
  padding-top: 0.45rem;
}
button {
  width: 170px;
  font-size: 1rem;
  background: #299a0b;
  background: -moz-linear-gradient(top,  #299a0b 0%, #299a0b 100%);
  background: -webkit-linear-gradient(top,  #299a0b 0%,#299a0b 100%);
  background: linear-gradient(to bottom,  #299a0b 0%,#299a0b 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#299a0b', endColorstr='#299a0b',GradientType=0 );

}
button:hover {
  background: #b4ddb4;
  background: -moz-linear-gradient(top,  #b4ddb4 0%, #83c783 17%, #52b152 33%, #008a00 67%, #005700 83%, #002400 100%);
  background: -webkit-linear-gradient(top,  #b4ddb4 0%,#83c783 17%,#52b152 33%,#008a00 67%,#005700 83%,#002400 100%);
  background: linear-gradient(to bottom,  #b4ddb4 0%,#83c783 17%,#52b152 33%,#008a00 67%,#005700 83%,#002400 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4ddb4', endColorstr='#002400',GradientType=0 );
}
.empty {
  /*border: 2px solid red!important;*/
  background-color: rgba(255, 0, 0, 0.4)!important;
}
.close {
  width: 25px;
  height: 25px;
  cursor: pointer;
}
.car-options {
  flex: 2;
  /*background-color: #007bff;*/
  min-height: 1rem;
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
}
.car-options > div {
  display: flex;
  /*justify-content: space-between;*/
  min-width: 30%;
  max-width: 30%;
  align-items: center;
  margin: 5px;
  flex: 1;
}
.car-options input[type=checkbox] {
  transform: scale(1.5);
  margin-right: 10px;
}
.car-options label {
  font-size: 0.7rem;
}
</style>