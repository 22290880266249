<template>
  <div class="cars">
    <SetCarForm :show="showForm" :car="editCar" @save="submitCarForm" @close="closeForm">
    </SetCarForm>
    <div @click="showForm = true" class="add-icon"></div>
    <div class="row">
      <div></div>
      <div v-for="s in sites" :key="s.id"><b>{{s.name}}</b></div>
    </div>
    <div class="row border-bottom" v-for="(car, i) in cars" :key="i + '' + car.id">
      <div class="car-info">
        <img :src="getImageSrc(car.image, 'cars')" alt="">
        <div>
          <span>{{car.name}}</span>
<!--          <template v-else>-->
<!--            <input class="car-name" v-model="b.brand">-->
<!--            <input class="car-name" v-model="b.name">-->
<!--          </template>-->
          <img src="../assets/pencil.svg" alt="" @click="editClick($event, car)">
        </div>
      </div>
      <template v-for="s in sites">
        <div :key="s.id + '' + car.id" class="site-info">
          <input type="checkbox" :checked="hasValue(car.available, s.id)" :id="s.id + '-' + car.id" @change="editCarAvailable($event, car, s.id)">
          <div class="deposit">
            <input type="text" placeholder="Цена" v-model="car.fixed_price[s.id]" @keydown="checkChange">
            <div><img src="../assets/floppy.svg" alt="" @click="editCarPrice(car.id, s.id, car.fixed_price[s.id])"></div>
          </div>
          <div class="deposit">
            <input type="text" placeholder="Депозит" v-model="car.deposit[s.id]" @keydown="checkChange">
            <div><img src="../assets/floppy.svg" alt="" @click="editCarDeposit(car.id, s.id, car.deposit[s.id])"></div>
          </div>
        </div>
      </template>
    </div>
    <Confirm :show="conf.show" :text="conf.text" @confirm="confirmed" @cancel="closeConfirm"></Confirm>
  </div>
</template>

<script>
import SetCarForm from "./SetCarForm";
import Confirm from "./Confirm";

import http from "../services/http";

export default {
  name: "Cars",
  components: {
    SetCarForm,Confirm
  },
  data() {
    return {
      sites: [],
      cars: [],
      showForm: false,
      editId: 0,
      editCar: null,
      formData: null,
      conf: {
        show: false,
        text: '',
        action: ''
      }
    }
  },
  created() {
    this.$store.dispatch('initCompleted').then(completed => {
      if (completed) {
        this.sites = this.$store.getters.sites;
        this.cars = this.$store.getters.cars;
        console.log("CARS: ", JSON.stringify(this.cars))
      }
    });
  },
  methods: {
    getImageSrc(image, key) {
      return http.getImageSrc(image, key)
    },
    notify(text, type, duration) {
      this.$emit('notification', {text, type, duration})
    },
    refreshCars() {
      this.$store.dispatch('refresh','cars').then(success => {
        if (success) this.cars = this.$store.getters.cars
      })
    },
    submitCarForm(e) {
      console.log(e);
      this.formData = e
      if (e.car.id > 0) this.openConfirm('Вы действительно хотите редактировать автомобиль?', 'edit')
      else this.openConfirm('Вы действительно хотите добавить автомобиль?', 'add')
    },
    editCarAvailable(e, car, site) {
      if (e && e.target && car && site) {
        const checked = e.target.checked
        if (checked != null) {
          const available = car.available
          console.log('CHECKED ', checked, available)
          if (checked) car.available.push(site)
          else car.available.splice(car.available.indexOf(site), 1)
          const data = {
            id: car.id,
            available: car.available
          }
          console.log(JSON.stringify(data))
          http.editCar(data, undefined, 'available').then(r => {
            console.log(r)
            if (r.data.error != null) this.notify('Произошла ошибка', 'danger')
            else this.notify('Успешно сохранено', undefined, 1500)
          })
        }
      }
    },
    checkChange(e) {
      const NUMBER_ALLOWED_CHARS_REGEXP = /^[0-9]*$/gm;
      if (!NUMBER_ALLOWED_CHARS_REGEXP.test(e.key)) e.preventDefault()
    },
    editCarPrice(id ,site_id, value) {
      const data = {id, site_id}
      data.fixed_price = parseInt(value)
      console.log(data)
      http.editCar(data, undefined, 'price').then(r => {
        console.log(r)
        if (!r.data.error) this.refreshCars()
      })
    },
    editCarDeposit(id ,site_id, value) {
      const data = {id, site_id}
      data.deposit = parseInt(value)
      console.log(data)
      http.editCar(data, undefined, 'deposit').then(r => {
        console.log(r)
        if (!r.data.error) this.refreshCars()
      })
    },
    hasValue(array, value) {
      return array.includes(value);
    },
    editClick(e, car) {
      if (e && car) {
        if (this.editCar == null) {
          this.editCar = car
          this.showForm = true
        }
        else this.editCar = null
      }
    },
    getFullName(obj) {
      if (!obj || (!obj.name || !obj.brand)) return '';
      else return obj.brand + ' ' + obj.name;
    },
    closeForm() {
      this.showForm = false
      if (this.editCar != null) {
        // console.log('CAR NOT NULL')
        this.editCar = null
        this.$forceUpdate()
      }
    },
    openConfirm(text, action) {
      this.conf.text = text
      this.conf.action = action
      this.conf.show = true
    },
    closeConfirm() {
      this.conf.show = false
      this.conf.text = this.conf.action = ''
    },
    async confirmed() {
      const request = await (() => {
        if (this.conf.action === 'edit') return http.editCar
        else return http.setNewCar
      })()
      request(this.formData.car, this.formData.file).then(r => {
        console.log(r);
        if (!r.data.error) {
          this.showForm = false
          this.refreshCars()
          this.closeConfirm()
        }
      })
    }
  }
}
</script>

<style scoped>
.cars {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 100%;
  /*background-color: #008a00;*/
  display: flex;
  flex-direction: column;
}
.cars > *:not(img) {
  min-height: 2rem;
}
.cars > img {
  width: 2.5rem;
  height: 2.5rem;
}
.cars > .row {
  width: 100%;
  display: flex;
  min-height: 2rem;
  margin-top: 20px;
}
.cars > .row > * {
  min-height: 2rem;
  flex: 1;
}
.car-info {
  display: flex;
  flex-direction: column;
}
.car-info > img {
  width: 16rem;
  height: 10rem;
  margin:  0 auto 20px auto;
}
.car-info > div {
  display: flex;
  /*flex-direction: row-reverse;*/
  justify-content: space-between;
  width: calc(100% - 50px);
  padding-left: 50px;
  align-items: center;
  margin-bottom: 5px;
}
.car-info > div img {
  width: 25px;
  height: 25px;
  margin-left: 30px;
  cursor: pointer;
}
.border-bottom {
  border-bottom: 1px solid rgba(0,0,0,0.3);
}
input[type=checkbox] {
  transform: scale(3)!important;
  margin: 0 auto;
  padding: 0;
  max-width: 30px;
  max-height: 30px;
}
.add-icon {
  width: 40px;
  height: 40px;
  box-shadow: 1px 1px 5px rgba(0,0,0,0.7);
  background: url("../assets/green_plus.svg") no-repeat;
  background-size: 170% 170%;
  background-position: center;
  border-radius: 5px;
  cursor: pointer;
}
span {
  float: left;
}
.car-name, .car-info span {
  font-size: 1.1rem;
  padding: 2px;
}
.car-name {
  text-align: right;
  border: none;
  border-radius: 3px;
  /*background-color: rgba(0,0,0,0.1);*/
  box-shadow: 1px 1px 5px rgba(0,0,0,0.7);
  max-width: 7.5rem;
}
.site-info {
  flex: 1;
  padding: 10px;
  /*background-color: #42b983;*/
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.deposit {
  min-height: 2.5rem;
  max-height: 2.5rem;
  /*background-color: #007bff;*/
  width: 200px;
  margin-left: calc(50% - 100px);
  display: flex;
  border-radius: 5px;
  box-shadow: 1px 1px 4px rgba(0,0,0,0.4);
}
.deposit > div {
  width: 30%;
  height: 100%;
  border-left: 1px solid rgba(0,0,0,0.4);
  position: relative;
  align-items: center;
  cursor: pointer;
}
.deposit input {
  width: 70%;
  border: none;
}
.deposit img {
  display: block;
  width: 1.65rem;
  height: 1.65rem;
  margin: 0.4rem auto;
}
input[type=checkbox] {
  transform: scale(3);
}
</style>