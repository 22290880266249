<template>
  <div class="r-opts">
    <img src="../assets/plus.svg" alt="" @click="formVisible = true">
    <div class="row head">
      <div>Услуга</div>
      <div>Цена</div>
      <div></div>
      <div v-for="(s, i) in sites" :key="i">{{s.name}}</div>
    </div>
    <div class="row" v-for="(o, i) in rental_options" :class="{'last': i === rental_options.length - 1}" :key="i">
      <div class="opt-names">
        <template v-if="editedOptionId === 0 || editedOptionId !== o.id">
          <span>{{o.eng}}</span>
          <span>{{o.rus}}</span>
        </template>
        <template v-else>
          <span><input type="text" v-model="o.eng"></span>
          <span><input type="text" v-model="o.rus"></span>
        </template>
      </div>
      <div>
        <span v-if="editedOptionId === 0 || editedOptionId !== o.id">{{o.price}}</span>
        <span v-else><input type="text" v-model="o.price" @keydown="checkChange"></span>
      </div>
      <div class="btns">
        <button @click="editedOptionId === 0 || editedOptionId !== o.id ? editedOptionId = o.id : editOption(o)">
          {{editedOptionId === 0 || editedOptionId !== o.id ? 'Редактировать' : 'Сохранить'}}
        </button>
        <button @click="editedOptionId === 0 || editedOptionId !== o.id ? deleteOption(o.id) : editedOptionId = 0">
          {{editedOptionId === 0 || editedOptionId !== o.id ? 'Удалить' : 'Отмена'}}
        </button>
      </div>
      <div v-for="(s, ind) in sites" :key="ind">
        <input type="checkbox" :checked="available(s.id, o.available)" @change="checkBoxClick($event, o, s.id)" :key="o.id + '-' + s.id">
      </div>
    </div>
    <div class="new-opt-body" v-if="formVisible" @click.self="formVisible = false">
      <div class="new-opt-form">
        <div>
          <h1>Новая услуга</h1>
        </div>
        <div>
          <label for="opt-eng">Название на английском</label>
          <input type="text" id="opt-eng" v-model="newOption.eng">
        </div>
        <div>
          <label for="opt-rus">Название на русском</label>
          <input type="text" id="opt-rus" v-model="newOption.rus">
        </div>
        <div>
          <button @click="saveNewOption">Сохранить</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../services/http";

export default {
  name: "RentalOptions",
  data() {
    return {
      formVisible: false,
      editedOptionId: 0,
      sites: [],
      rental_options: [],
      newOption: {
        eng: '',
        rus: ''
      }
    }
  },
  created() {
    this.$store.dispatch('initCompleted').then(() => {
      this.sites = this.$store.getters.sites
    })
    this.getOptions()
  },
  methods: {
    getOptions() {
      http.getRentalOptions().then(r => {
        console.log(r)
        if (!r.data.error) {
          this.rental_options = r.data.rental_options || []
        }
      })
    },
    openNotification(text, type) {
      this.$emit('notification', {text, type, duration: 5000})
    },
    saveNewOption() {
      // this.rental_options.push({id: this.rental_options.length + 1, price: (this.rental_options.length + 1) * 10, eng: this.newOption.eng, rus: this.newOption.rus, available: [1,2,3]})
      const data = {eng: this.newOption.eng, rus: this.newOption.rus}
      this.formVisible = false
      this.newOption.eng = this.newOption.rus = ''
      http.setNewRentalOption(data).then(r => {
        console.log(r)
        if (!r.data.error) {
          this.formVisible = false
          this.newOption.eng = this.newOption.rus = ''
          this.getOptions()
          this.openNotification('Успешно сохранено', 'success')
        } else {
          this.openNotification('Ошибка сохранения', 'danger')
        }
      })
    },
    available(siteId, available) {
      return (available != null && siteId > 0) && available.indexOf(siteId) >= 0
    },
    editOption(option) {
      console.log(JSON.stringify(option))
      option.price = +option.price
      http.editRentalOption(option, 'rental_options').then(r => {
        if (!r.data.error) {
          this.editedOptionId = 0
          this.openNotification('Успешно сохранено', 'success')
        } else {
          this.openNotification('Ошибка редактирования', 'danger')
        }
      })
    },
    deleteOption(id) {
      console.log(id)
    },
    checkBoxClick(event, option, siteId) {
      if (event && event.target) {
        console.log(event.target.checked, JSON.stringify(option), siteId)
        if(event.target.checked === true) option.available.push(siteId)
        else option.available.splice(option.available.indexOf(siteId), 1)
        setTimeout(() => {
          this.$forceUpdate()
          // console.log(JSON.stringify(option))
          http.editRentalOption({id: option.id, available: option.available}, 'rental-available').then(r => {
            if (!r.data.error) this.openNotification('Успешно сохранено', 'success')
            else this.openNotification('Ошибка редактирования', 'danger')
          })
        }, 10)
      }
    },
    checkChange(e) {
      if (e.keyCode !== 8 && e.keyCode !== 46) {
        const NUMBER_ALLOWED_CHARS_REGEXP = /^[0-9[\b]]*$/gm;
        if (!NUMBER_ALLOWED_CHARS_REGEXP.test(e.key)) e.preventDefault()
      }
    },
  }
}
</script>

<style scoped>
  .r-opts {
    width: 100%;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 2px 4px rgba(0,0,0,0.3),
                2px 2px 4px rgba(0,0,0,0.6);
    margin-top: 50px;
    position: relative;
  }
  .r-opts > .row {
    width: 100%;
    display: flex;
    min-height: 3rem;
  }
  .row:not(:first-child) > * {
    flex: 1;
    font-size: 1.2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .row:not(:first-child) > *:not(:first-child) {
    border-left: 1px solid rgba(0,0,0,0.2);
  }
  .r-opts > img {
    position: absolute;
    top: -50px;
    left: 0;
    width: 50px;
    height: 50px;
  }
  .r-opts .btns {
    padding: 5px 0;
  }
  .r-opts button {
    height: 2rem;
    min-width: 60%;
    margin: auto;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
  }
  .r-opts button:first-child {
    background-color: #006e2e;
  }
  .r-opts button:not(:first-child) {
    margin-top: 10px;
    background-color: darkred;
  }
  .row.head {
    font-weight: 700;
    /*border: 1px solid rgba(0,0,0,0.2);*/
  }
  .row:not(.head):not(:first-child) {
    /*border-left: 1px solid rgba(0,0,0,0.2);*/
    /*border-right: 1px solid rgba(0,0,0,0.2);*/
    border-top: 2px solid rgba(0,0,0,0.2);
  }
  .row.last {
    /*border-bottom: 1px solid rgba(0,0,0,0.2);*/
  }
  .row .opt-names span {
    padding: 5px 0;
  }
  .row .opt-names span:first-child {
    border-bottom: 1px solid rgba(0,0,0,0.2);
    /*margin-bottom: 10px;*/
  }
  .row input[type=checkbox] {
    /*transform: scale(2.2);*/
    width: 40px;
    height: 40px;
    margin: auto;
  }
  .row input[type=text] {
    border: none;
    box-shadow: 2px 2px 5px rgba(0,0,0,0.4);
    min-height: 2rem;
  }

  .new-opt-body {
    width: 100%;
    min-height: 880px!important;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1000;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .new-opt-form {
    border-radius: 10px;
    background-color: #ffffff;
    /*height: 300px;*/
    width: 40%;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .new-opt-form > div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .new-opt-form > div:not(:first-child) {
    margin-top: 20px;
  }
  .new-opt-form label {
    text-align: left;
    margin-bottom: 5px;
  }
  .new-opt-form input[type=text] {
    height: 1.5rem;
  }
  .new-opt-form button {
    background-color: #008a00;
    color: #ffffff;
    width: 150px;
    margin-left: auto;
  }
</style>