<template>
  <div class="automatization">
    <div class="row">
      <div v-for="(s, i) in sites" :key="i"><b>{{s.name}}</b></div>
    </div>
    <div class="row">
      <template v-for="(s, i) in sites">
        <div class="time" :key="i">
          <span>Фиксированное время</span>
          <div class="inputs">
            <input type="radio" :name="s.id" :checked="s.automatisation.is_fixed === true" @change="onInputChange(s, true)">
            <input type="time" v-model="s.automatisation.fixed_time">
          </div>
        </div>
      </template>
    </div>
    <div class="row">
      <template v-for="(s, i) in sites">
        <div class="time" :key="i">
          <span>Добавочное время (в минутах)</span>
          <div class="inputs">
            <input type="radio" :name="s.id" :checked="!s.automatisation.is_fixed" @change="onInputChange(s)">
            <input type="number" v-model="s.automatisation.added_time">
          </div>
        </div>
      </template>
    </div>
    <div class="row">
      <button @click="saveAutomatisation">Сохранить</button>
    </div>
    <Notification></Notification>
  </div>
</template>

<script>
import http from "../services/http";

import Notification from "./Notification";

export default {
  name: "Automatization",
  components: {
    Notification
  },
  data() {
    return {
      sites: [],
      notification: {
        show: false,
        type: '',
        text: ''
      }
    }
  },
  created() {
    this.$store.dispatch('initCompleted').then(completed => {
      if (completed) {
        // this.sites = this.$store.getters.sites;
        for (let site of this.$store.getters.sites) {
          this.sites.push(site)
        }
        console.log(JSON.stringify(this.sites))
      }
    })
  },
  methods: {
    openNotification(text, type = 'success') {
      this.notification.text = text
      if (type) this.notification.type = type
      this.notification.show = true
    },
    closeNotification() {
      this.notification.show = false
      this.$nextTick().then(() => {
        this.notification.type = this.notification.text = ''
      })
    },
    onInputChange(site, isFixed) {
      // console.log(e.target.checked)
      if (site && site.automatisation) site.automatisation.is_fixed = isFixed === true
    },
    saveAutomatisation() {
      const array = []
      for (let i = 0; i < this.sites.length; i++) {
        array.push(this.sites[i].automatisation)
        array[i].added_time = +array[i].added_time
        if (i === this.sites.length - 1) {
          console.log(JSON.stringify(array))
          http.editAutomatisation(array).then(r => {
            if (r.data.error) {
              this.openNotification('Не удалось сохранить новые данные', 'danger')
              this.sites = this.$store.getters.sites
              return
            }
            this.openNotification('Изменения успешно сохранены', 'success')
            this.$store.dispatch('refresh', 'sites').then(() => {
              this.sites = this.$store.getters.sites
            })
          })
        }
      }
    }
  }
}
</script>

<style scoped>
.automatization {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.automatization > .row {
  width: 100%;
  display: flex;
}
.row > * {
  flex: 1;
}
.row:not(:first-child) {
  margin-top: 10px;
}
.row button {
  max-width: 200px;
  margin-left: auto;
  margin-top: 10px;
  background-color: #006e2e;
}
.time {
  display: flex;
  flex-direction: column;
  text-align: left;
}
.inputs {
  margin-top: 10px;
}
input[type=radio] {
  transform: scale(2);
}
input[type=time] {
  transform: scale(1.2);
  margin-left: 20px;
}
input[type=number] {
  height: 1.8rem;
  margin-left: 14px;
}
</style>